<template>
    <section class="edit-point">
        <div class="edit-point-form">
            <button class="edit-point-form__exit-btn"
                    @click="
                    (e)=>
                {
                e.preventDefault();
                this.$emit('closeAddVotingt');
                }
                "
                >
                <img src="../../../../../assets/icons/components/exit-btn.svg" />
            </button>
            <section class="edit-point-form__form-wrapper">
                <h2 class="edit-point-form__title">
                    {{ votingToUpdate.id == 0 ? "Dodaj" : "Edytuj" }} głosowanie
                </h2>
                <section class="edit-point-form__row" v-if="pointIds != null && pointIds.length == 0">
                    <label class="edit-point-form__label">{{votingToUpdate.is_application ? 'Wniosek' : 'Głosowanie'}} w sprawie</label>
                    <input type="text"
                           class="edit-point-form__input"
                           v-model="votingToUpdate.name"
                           v-error="{ errors, input_key: 'name' }" />
                    <ErrorMessage v-if="isError('name', this.errors)" :errorMsgs="passErrorMsg('name', this.errors)" />
                </section>


                <section class="edit-point-form__checkbox-top" style="margin-bottom:10px;">
                    <section class="edit-point-form__checkbox-wrapper">
                        <input type="checkbox"
                               class="edit-point-form__checkbox"
                               :checked="votingToUpdate.is_application" />
                        <section class="edit-point-form__checkbox-mark">
                            <img src="../../../../../assets/icons/components/accept-mark.svg" />
                        </section>
                    </section>
                    <label class="edit-point-form__label edit-point-form__label--checkbox"
                           @click="votingToUpdate.is_application = !votingToUpdate.is_application; votingToUpdate.check_presence = false;">Wniosek</label>

                    <section class="edit-point-form__checkbox-wrapper">
                        <input type="checkbox"
                               class="edit-point-form__checkbox"
                               :checked="votingToUpdate.check_presence" />
                        <section class="edit-point-form__checkbox-mark">
                            <img src="../../../../../assets/icons/components/accept-mark.svg" />
                        </section>
                    </section>
                    <label class="edit-point-form__label edit-point-form__label--checkbox"
                           @click="votingToUpdate.check_presence = !votingToUpdate.check_presence; votingToUpdate.is_application = false; changePresenceOption()">Sprawdzenie obecności</label>
                </section>

                <section class="edit-point-form__section-triple" v-if="!votingToUpdate.check_presence">

                    <section class="edit-point-form__col-triple col-width2">
                        <label class="edit-point-form__label">Tryb głosowania</label>
                        <select class="edit-point-form__input"
                                v-model="votingToUpdate.selected"
                                @change="votingTypeChanged">
                            <option v-for="s in votingToUpdate.modes" :value="s.id" :key="s.id"> {{s.type}}</option>
                        </select>

                    </section>

                    <section class="edit-point-form__col-triple">
                        <label class="edit-point-form__label">Kworum [%]</label>
                        <input type="number" min="0" max="100"
                               class="edit-point-form__input"
                               v-model="votingToUpdate.kworum"
                               @change="checkNumber($event)"
                               v-tooltip="'Wymagane kworum w ujęciu procentowym [%]'"
                               v-error="{ errors, input_key: 'kwor_um' }" />
                        <ErrorMessage v-if="isError('kwor_um', this.errors)" :errorMsgs="passErrorMsg('kwor_um', this.errors)" />
                    </section>

                </section>

                <section class="edit-point-form__row">
                    <span class="edit-point-form__tip">{{displayVotingTribeDescription()}}</span>
                </section>

                <section class="edit-point-form__row">
                    <label class="edit-point-form__label">Odpowiedzi</label>
                    <textarea type="text" rows="4" ref="votinAnserwEditing"
                              class="edit-point-form__input"
                              v-model="votingToUpdate.description"
                              v-error="{ errors, input_key: 'desc_ription' }" :disabled="votingToUpdate.check_presence" />
                    <ErrorMessage v-if="isError('desc_ription', this.errors)" :errorMsgs="passErrorMsg('desc_ription', this.errors)" />
                </section>

                <section class="edit-point-form__section-triple" v-if="!votingToUpdate.check_presence">
                    <section class="edit-point-form__col-triple col-width">
                        <label class="edit-point-form__label">Szablony</label>
                        <select ref="templateAnswerSelect"
                                class="edit-point-form__input"
                                v-model="votingToUpdate.selected_answers" @click="onChange($event)">
                            <option v-for="s in votingToUpdate.templates" :key="s.id"> {{s.temp}}</option>
                        </select>
                    </section>


                    <section class="edit-point-form__col-triple">
                        <label class="edit-point-form__label">Min. wybór</label>
                        <input type="number" min="0"
                               class="edit-point-form__input"
                               v-model="votingToUpdate.min"
                               @change="checkNumberMin($event)"
                               v-error="{ errors, input_key: 'm_in' }" />
                        <ErrorMessage v-if="isError('m_in', this.errors)" :errorMsgs="passErrorMsg('m_in', this.errors)" />
                    </section>

                    <section class="edit-point-form__col-triple">
                        <label class="edit-point-form__label">Maks. wybór</label>
                        <input type="number" min="1"
                               class="edit-point-form__input"
                               v-model="votingToUpdate.max"
                               @change="checkNumberMax($event)"
                               v-error="{ errors, input_key: 'm_ax' }" />
                        <ErrorMessage v-if="isError('m_ax', this.errors)" :errorMsgs="passErrorMsg('m_ax', this.errors)" />
                    </section>

                </section>


                <section class="edit-point-form__point-status" v-if="!votingToUpdate.check_presence">
                    <label class="edit-point-form__label">Jawność głosowania</label>
                    <section class="edit-point-form__checkbox-top">
                        <section class="edit-point-form__checkbox-wrapper">
                            <input type="checkbox"
                                   class="edit-point-form__checkbox"
                                   :checked="votingToUpdate.is_public_voting" />
                            <section class="edit-point-form__checkbox-mark">
                                <img src="../../../../../assets/icons/components/accept-mark.svg" />
                            </section>
                        </section>
                        <label class="edit-point-form__label edit-point-form__label--checkbox"
                               @click="votingToUpdate.is_public_voting = !votingToUpdate.is_public_voting; votingToUpdate.is_secret_voting = false">Jawne</label>

                        <section class="edit-point-form__checkbox-wrapper">
                            <input type="checkbox"
                                   class="edit-point-form__checkbox"
                                   :checked="votingToUpdate.is_secret_voting"
                                   v-error="{ errors, input_key: 'pub_sec' }" />

                            <section class="edit-point-form__checkbox-mark">
                                <img src="../../../../../assets/icons/components/accept-mark.svg" />
                            </section>
                        </section>
                        <label class="edit-point-form__label edit-point-form__label--checkbox"
                               @click="votingToUpdate.is_secret_voting = !votingToUpdate.is_secret_voting; votingToUpdate.is_public_voting = false">Tajne</label>

                    </section>
                    <ErrorMessage v-if="isError('pub_sec', this.errors)" :errorMsgs="passErrorMsg('pub_sec', this.errors)" />
                </section>

                <section class="edit-point-form__row" v-if="!votingToUpdate.check_presence">
                    <label class="edit-point-form__label">Kod PIN</label>
                    <input type="text"
                           maxlength="4"
                           placeholder="PIN musi zawierać 4 cyfry"
                           class="edit-point-form__input"
                           @keypress="onlyNumber"
                           v-model="votingToUpdate.pinCode"
                           v-error="{ errors, input_key: 'pin_code' }" />
                    <ErrorMessage v-if="isError('pin_code', this.errors)" :errorMsgs="passErrorMsg('pin_code', this.errors)" />
                </section>

                <section class="edit-point-form__checkbox-section">
                    <label class="edit-point-form__label">Uprawnieni do głosowania</label>
                    <section v-for="group in groups"
                             :key="group.id"
                             class="
                            edit-point-form__checkbox-top
                            edit-point-form__checkbox-top--col
                        ">
                        <CheckBoxButton :id="`${group.id}-comision`"
                                        :value="group.id"
                                        @setGroupId="
                                        (value)=>
                            (this.selected_groups = value)
                            "
                            :idsArray="selected_groups"
                            :checked="
                            selected_groups.indexOf(group.id) !== -1
                            "
                            />
                            <label class="
                                edit-point-form__label
                                edit-point-form__label--checkbox
                                edit-point-form__label--normal
                            "
                                   :for="`${group.id}-comision`">{{ group.name }}</label>
                    </section>
                    <ErrorMessage v-if="isError('selected_groups', this.errors)" :errorMsgs="passErrorMsg('selected_groups', this.errors)" />
                </section>

                <section v-if="!votingToUpdate.check_presence">
                    <label class="edit-point-form__label">Opcje</label>

                    <div class="form-bootstrap-check" style="padding-left: 0px;">
                        <button class="button-excluded" @click="showExcludedUsersModalAction()" :disabled="votingToUpdate.id <= 0">
                            <img src="../../../../../assets/icons/components/user-slash.svg" style="width: 20px;" />
                        </button>
                        <label class="form-bootstrap-check-label">Wyłącz użytkowników z głosowania ({{votingToUpdate.excluded_count}})</label>
                        <section name="externalTrigger2"
                                 class="edit-point-form__tip-btn">
                            <img src="../../../../../assets/icons/components/tooltip.svg"
                                 class="edit-point-form__tooltip" />
                            <Hint style="left: calc(100% - 310px)" title="Wyłączenia"
                                  text="Możliwość wyłączenia wybranych radnych z tego głosowania. Opcja aktywna po zapisaniu głosowania." />
                        </section>
                    </div>

                    <div class="form-bootstrap-check" style="padding-left: 0px;" v-if="pointIds != null && pointIds.length == 0">
                        <button class="button-excluded" @click="showAddMultipleVotingsModalAction()">
                            <img src="../../../../../assets/icons/components/voting-edit-white.svg" style="width: 20px;" />
                        </button>
                        <label class="form-bootstrap-check-label">Utwórz wiele głosowań</label>
                        <section name="externalTrigger2"
                                 class="edit-point-form__tip-btn">
                            <img src="../../../../../assets/icons/components/tooltip.svg"
                                 class="edit-point-form__tooltip" />
                            <Hint title="Utwórz wiele głosowań"
                                  text="Możliwość utworzenia wielu głosowań o takich samych parametrach." />
                        </section>
                    </div>

                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="hide-voting" v-model="votingToUpdate.is_hide_voting" />
                        <label class="form-bootstrap-check-label" for="hide-voting">Ukryj głosowanie</label>
                        <section name="externalTrigger2"
                                 class="edit-point-form__tip-btn">
                            <img src="../../../../../assets/icons/components/tooltip.svg"
                                 class="edit-point-form__tooltip" />
                            <Hint title="Ukryj głosowanie"
                                  text="Po zaznaczeniu tej opcji, głosowanie będzie widoczne tylko w aplikacji Administratora." />
                        </section>
                    </div>

                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="special-voting" v-model="votingToUpdate.is_special_voting" @click="selectSpecialVotingChangeVotingType()" />
                        <label class="form-bootstrap-check-label" for="special-voting">Uruchom głosowanie specjalne</label>
                        <section name="externalTrigger2"
                                 class="edit-point-form__tip-btn">
                            <img src="../../../../../assets/icons/components/tooltip.svg"
                                 class="edit-point-form__tooltip" />
                            <Hint title="Głosowanie specjalne"
                                  text="W głosowaniu specjalnym, opiniujemy za pomocą wyboru Tak/Nie/Wstrzymuje się, każdą pozycję wpisaną w polu odpowiedzi." />
                        </section>
                    </div>

                    <div class="form-bootstrap-check form-bootstrap-switch">
                        <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="automatic-voting" v-model="is_automatic" />
                        <label class="form-bootstrap-check-label" for="automatic-voting">Włącz automatyczny start głosowania</label>
                        <section name="externalTrigger2"
                                 class="edit-point-form__tip-btn">
                            <img src="../../../../../assets/icons/components/tooltip.svg"
                                 class="edit-point-form__tooltip" />
                            <Hint style="left: calc(100% - 310px)" title="Automatyczny start"
                                  text="Po rozpoczęciu głosowania głosowanie trwa wyznaczony czas, następnie jest kończone i pojawia się kolejne w celu jego rozpoczęcia." />
                        </section>
                    </div>

                    <!--<section class="edit-point-form__switch-section">

                    <SwitchButton2 label="Automatyczny start głosowania"
                                   :isPublic="is_automatic"
                                   @setIsPublic="(value) => (is_automatic = value)" />
                    <section name="externalTrigger2"
                             class="edit-point-form__tip-btn">
                        <img src="../../../../../assets/icons/components/tooltip.svg"
                             class="edit-point-form__tooltip" />
                        <Hint style="left: calc(100% - 310px)" title="Automatyczny start"
                              text="Po rozpoczęciu głosowania głosowanie trwa wyznaczony czas, następnie jest kończone i pojawia się kolejne w celu jego rozpoczęcia." />
                    </section>
                </section>-->
                    <section class="edit-point-form__col-double" v-if="is_automatic">
                        <label class="edit-point-form__label">Kolejne głosowanie</label>
                        <select class="edit-point-form__input"
                                v-model="selectedAutomaticVoting">
                            <option v-for="s in automaticVotingOptions" :value="s.id" :key="s.id"> {{s.type}}</option>
                        </select>
                    </section>
                </section>

                <section class="edit-point-form__buttons-bottom gap-2" style="padding: 0px;">
                    <button class="edit-point-form__bottom-button" 
                            @click="
                            (e)=>
                        {
                        e.preventDefault();
                        this.$emit('closeAddVotingt');
                        }
                        "
                        >
                        Anuluj
                    </button>
                    <button class="
                            edit-point-form__bottom-button
                            edit-point-form__bottom-button--red" style="width: 246px" @click="handleSaveVoting()">
                        Zapisz
                    </button>
                </section>
                <section class="edit-point-form__buttons-bottom gap-2" style="margin-top: 10px; padding-bottom: 56px;">
                    <button v-if="isInteractive && pointIds != null && this.pointIds.length == 0" style="flex: 1;" class="
                            edit-point-form__bottom-button
                            edit-point-form__bottom-button--red" @click="handleSaveVotingAndStart()">
                        Zapisz i uruchom
                    </button>
                </section>
            </section>
        </div>
        <LoaderInside v-if="isLoading" />
        <Transition>
            <ExcludedUsersModal v-if="showExcludedUsersModal && votingToUpdate.id > 0" :vname="votingToUpdate.name" :basicData="basicData" @closeExcludedUsersModal="closeExcludedUsersModal" @saveExcludedUsers="saveExcludedUsers" />
        </Transition>
        <Transition>
            <MultipleVotingsModal v-if="showMultipleVotingModal" :globalParametersForMultiple="globalParametersForMultiple" @closeAddMultipleVotingsModalAction="closeAddMultipleVotingsModalAction" @saveMultipleVotings="saveMultipleVotingsForPoint" />
        </Transition>
    </section>
</template>

<script charset="utf-8">
    //import SwitchButton2 from "../../../../BasicControls/SwitchButtonUni.vue";
    import Hint from "../../../../BasicControls/Hint.vue";
    import CheckBoxButton from "../../../../BasicControls/CheckBoxButton.vue";
    import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
    import ErrorMessage from "../../../../BasicControls/ErrorMessage.vue";
    import MeetingService from "../../../../../services/meetings.service";
    import VotingService from "../../../../../services/voting.service";
    import ExcludedUsersModal from "../Modals/ExcludedUsersModal.vue"
    import MultipleVotingsModal from "../Modals/AddMultipleVotingsModal"
    import {
        passErrorMsg,
        isError,
        setErrors,
    } from "../../../../../formValidation/main";

    export default {
        name: "AddEditVoting",
        data() {
            return {
                selected_groups: [...this.voting.selected_groups],
                groups: [],
                isLoading: false,
                errors: [],
                temSelectedDescriptionForApplication: '',
                basicData: {},
                pointName: this.voting.name,
                globalParametersForMultiple: {},
                showExcludedUsersModal: false,
                showMultipleVotingModal: false,
                votingToUpdate: this.voting,
                is_automatic: this.$store.state.interactiveStore.startAutomaticVotingByTimeSet.isAutomatic,
                disableChangeAnswers: false,
                automaticVotingOptions: [
                    { id: 5, type: 'Po 5 sekundach'},
                    { id: 10, type: 'Po 10 sekundach'},
                    { id: 15, type: 'Po 15 sekundach'},
                    { id: 20, type: 'Po 20 sekundach'},
                    { id: 30, type: 'Po 30 sekundach'},
                    { id: 60, type: 'Po 60 sekundach'},
                    { id: 120, type: 'Po 120 sekundach'},
                    { id: 180, type: 'Po 180 sekundach'},
                ],
                selectedAutomaticVoting: this.$store.state.interactiveStore.startAutomaticVotingByTimeSet.time,
            };
        },
        components: {
            CheckBoxButton,
            LoaderInside,
            ErrorMessage,
            Hint,
            ExcludedUsersModal,
            MultipleVotingsModal
        },
        props: {
            voting: Object,
            pointIds: Array,
            mode: {
                type: String,
                default: "standard"
            }
        },
        computed: {
            isInteractive() {
                return this.$store.state.appStore.isInInteractiveMode;
            },
        },
        methods: {
            saveExcludedUsers(users) {

                VotingService.excludeUserFromVoting(this.votingToUpdate.id, this.votingToUpdate.sid, users)
                    .then((response) => {
                        this.showExcludedUsersModal = false;
                        this.basicData = {};
                        this.votingToUpdate.excluded_count = response.data;
                    });
            },
            closeExcludedUsersModal() {
                this.showExcludedUsersModal = false;
                this.basicData = {};
            },
            showExcludedUsersModalAction() {

                VotingService.getUsersForVotingExcluded(this.votingToUpdate.id, this.votingToUpdate.sid)
                    .then((response) => {
                        this.basicData = response.data;
                        this.showExcludedUsersModal = true;
                    });
            },
            showAddMultipleVotingsModalAction() {
                this.errors = [];

                if (this.validate(this.votingToUpdate) == false)
                    return;

                var data = {
                    id: 0,
                    pid: this.votingToUpdate.pid,
                    sid: this.votingToUpdate.sid,
                    name: this.votingToUpdate.name,
                    description: this.votingToUpdate.description,
                    kworum: this.votingToUpdate.kworum,
                    max: this.votingToUpdate.max,
                    min: this.votingToUpdate.min,
                    pinCode: this.votingToUpdate.pinCode,
                    is_public_voting: this.votingToUpdate.is_public_voting,
                    is_secret_voting: this.votingToUpdate.is_secret_voting,
                    is_application: this.votingToUpdate.is_application,
                    check_presence: this.votingToUpdate.check_presence,
                    is_special_voting: this.votingToUpdate.is_special_voting,
                    selected_groups: this.selected_groups,
                    selected_answers: this.votingToUpdate.selected_answers,
                    selected: this.votingToUpdate.selected,
                    hide: this.votingToUpdate.is_hide_voting,
                };

                this.globalParametersForMultiple = data;
                this.showMultipleVotingModal = true;
            },
            closeAddMultipleVotingsModalAction() {
                this.showMultipleVotingModal = false;
                this.globalParametersForMultiple = {};
            },
            saveMultipleVotingsForPoint() {
                this.globalParametersForMultiple = {};
                this.showMultipleVotingModal = false;
                this.requestSuccess();
            },
            onlyNumber($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if ((keyCode < 48 || keyCode > 57)) {
                    $event.preventDefault();
                }
            },
            votingTypeChanged() {
                let matchTemplateAnswer = this.votingToUpdate.templates.find(answer => {
                    return answer.temp.includes("ZA");
                })
                if (this.votingToUpdate.selected != 1) {
                    this.$refs.votinAnserwEditing.disabled = true;
                    this.$refs.templateAnswerSelect.disabled = true;
                    this.votingToUpdate.selected_answers = matchTemplateAnswer.temp;
                    this.setDataToDescription(this.votingToUpdate.selected_answers);
                    this.votingToUpdate.is_special_voting = false;
                }
                else {
                    this.$refs.votinAnserwEditing.disabled = false;
                    this.$refs.templateAnswerSelect.disabled = false;
                }
            },
            displayVotingTribeDescription() {
                switch (this.votingToUpdate.selected) {
                    case 1:
                        return "";
                    case 2:
                        return "Liczba głosów za musi być większa od liczby głosów przeciw.";
                    case 3:
                        return "Liczba głosów za musi być większa od sumy głosów przeciw oraz wstrzymujących się.";
                    case 4:
                        return "Liczba głosów za musi być większa od połowy liczby całego składu rady.";
                    case 5:
                        return "Liczba głosów za musi być większa bądź równa 3/5 całego składu rady.";
                    default:
                        return "";
                }
            },
            changePresenceOption() {
                if (this.votingToUpdate.check_presence) {
                    this.pointName = this.votingToUpdate.name;
                    this.votingToUpdate.description = 'OBECNY';
                    this.votingToUpdate.name = 'Sprawdzenie obecności';
                }
                else {
                    this.setDataToDescription(this.votingToUpdate.templates[0].temp);
                    this.votingToUpdate.name = this.pointName;
                }
            },
            selectSpecialVotingChangeVotingType() {
                this.votingToUpdate.is_special_voting = !this.votingToUpdate.is_special_voting;

                if (this.votingToUpdate.is_special_voting) {
                    this.votingToUpdate.selected = 1;
                    this.$refs.votinAnserwEditing.disabled = false;
                    this.$refs.templateAnswerSelect.disabled = false;
                }
            },
            validate(data) {
                var bo = true;
                var err = {};
                if(data.name.trim().length <= 0){
                    err.name = ['Pole wymagane'];
                    bo = false;
                }

                if (data.description.trim().length <= 0) {
                    err.desc_ription = ['Uzupełnij pole "Odpowiedzi" lub wybierz szablon odpowiedzi']
                    bo = false
                }

                if (data.is_secret_voting == false && data.is_public_voting == false) {
                    err.pub_sec = ['Wybierz jawność głosowania']
                    bo = false
                }

                if (this.selected_groups.length < 1) {
                    err.selected_groups = ['Zaznacz grupę lub grupy, które mają być uprawnione do tego głosowania']
                    bo = false
                }

                if (data.pinCode.length > 0 && data.pinCode.length != 4) {
                    err.pin_code = ['Kod PIN musi zawierać 4 cyfry']
                    bo = false
                }

                if (!bo) {
                    this.isLoading = false;
                    this.errors = setErrors(err)
                }
                return bo
            },
            onChange(event) {
                this.setDataToDescription(event.target.value);
            },
            checkNumber(event) {
                if (event.target.value > 100) {
                    this.votingToUpdate.kworum = 100;
                }
                else if (event.target.value < 0) {
                    this.votingToUpdate.kworum = 0;
                }
                else {
                    if (event.target.value.includes(".")) {
                        this.votingToUpdate.kworum = event.target.value.split(".")[0];
                    }

                    if (event.target.value.includes(",")) {
                        this.votingToUpdate.kworum = event.target.value.split(",")[0];
                    }

                }
            },
            checkNumberMin(event) {
                if (event.target.value < 0) {
                    this.votingToUpdate.min = 0;
                }
            },
            checkNumberMax(event) {
                if (event.target.value <= 0) {
                    this.votingToUpdate.max = 1;
                }
            },
            setDataToDescription(stringData) {
                this.votingToUpdate.description = '';
                var des = stringData.split('/');
                for (var i = 0; i < des.length; i++) {
                    this.votingToUpdate.description += des[i] + '\r\n';
                }
            },
            async internalSaveVoting() {
                this.errors = [];

                if (this.validate(this.votingToUpdate) == false)
                    throw new Error("Failed to validate request data");

                var data = {
                    id: this.votingToUpdate.id,
                    sid: this.votingToUpdate.sid,
                    name: this.votingToUpdate.name,
                    description: this.votingToUpdate.description,
                    kworum: this.votingToUpdate.kworum,
                    max: this.votingToUpdate.max,
                    min: this.votingToUpdate.min,
                    pinCode: this.votingToUpdate.pinCode,
                    is_public_voting: this.votingToUpdate.is_public_voting,
                    is_secret_voting: this.votingToUpdate.is_secret_voting,
                    is_application: this.votingToUpdate.is_application,
                    check_presence: this.votingToUpdate.check_presence,
                    is_special_voting: this.votingToUpdate.is_special_voting,
                    selected_groups: this.selected_groups,
                    selected_answers: this.votingToUpdate.selected_answers,
                    selected: this.votingToUpdate.selected,
                    hide: this.votingToUpdate.is_hide_voting,
                };

                if (this.pointIds != null && this.pointIds.length > 0) {

                    data.pids = this.pointIds;

                    let response = await VotingService.addVotingToMultiplePoints(this.voting.sid, data);
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                }
                else {

                    data.pid = this.votingToUpdate.pid;

                    let response = await VotingService.addOrUpdateVoting(this.voting.pid, data)
                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }
                    this.votingToUpdate.id = response.data.id;
                }
            },
            async handleSaveVotingAndStart() {
                try {
                    this.isLoading = true;
                    await this.internalSaveVoting();
                    this.$emit('startVoting', this.votingToUpdate.pid, this.votingToUpdate.id);
                    this.requestSuccess();
                }
                catch{
                    this.requestError()
                }
                finally {
                    this.isLoading = false;
                }
            },
            async handleSaveVoting() {
                try {
                    this.isLoading = true;
                    await this.internalSaveVoting();
                    this.requestSuccess();
                }
                catch{
                    this.requestError()
                }
                finally {
                    this.isLoading = false;
                }
            },
            requestSuccess() {
                this.votingToUpdate = {};
                this.groups = [];
                this.errors = [];
                this.selected_groups = [];
                this.isLoading = false;
                this.$emit("getSessionVotings");
                this.$emit("reloadData");
                this.$emit("closeAddVotingt");

                var data = { isAutomatic: this.is_automatic, time: this.selectedAutomaticVoting };
                this.$store.commit("setStartAutomaticVoting", data);
            },
            requestError() {
                this.isLoading = false;
            },
            passErrorMsg,
            isError,
            setErrors,
        },
        async mounted() {
            try {
                this.isLoading = true;
                let response = await MeetingService.groupsForVoting(this.votingToUpdate.sid);
                if (response.status !== 200) {
                    throw await Promise.reject(response);
                }
                this.groups = response.data;

                if (this.votingToUpdate.id == 0 && this.mode === "standard") {
                    this.selected_groups = [];
                    for (var i = 0; i < this.groups.length; i++) {
                        this.selected_groups.push(this.groups[i].id);
                    }
                }
            }
            finally {
                this.isLoading = false;
            }
        },
    };
</script>

<style lang="scss" scoped>
    .button-excluded {
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        //border-radius: 3px;
        font-weight: 300;
        font-size: 15px;
        line-height: 132%;
        background: #d80418;
        color: white;
        width: 42px;
        height: 24px;
        border-radius: 100px;
        margin-left: 5px;

        &:hover {
            background: #ad0313 !important;
        }

        &:disabled {
            background: #00082c75 !important;
            cursor: default;
        }
    }


    .col-width {
        min-width: 300px;
    }

    .col-width2 {
        min-width: 400px;
    }

    .edit-point {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 9;
    }

    .edit-point-form {
        padding: 32px 56px;
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        max-height: 90vh;
        padding-bottom: 0px;
        padding-right: 0px;

        &__form-wrapper {
            max-height: 80vh;
            overflow-y: auto;
            padding-right: 56px;

            &::-webkit-scrollbar {
                width: 12px;
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #b5b8bd;
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #b5b8bd;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
                border: 0.8px solid #dce4ee;
                border-radius: 30px;
                box-shadow: inset 0px 0px 0px 0px #f0f0f0;
            }
        }

        &__exit-btn {
            border: 0px;
            background-color: transparent;
            position: relative;
            right: calc(-100% + 48px);
        }

        &__row {
            margin-bottom: 10px;
        }

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            width: 100%;
            padding-bottom: 32px;
            //margin-bottom: 16px;

            &--small {
                font-size: 20px;
                border-bottom: 0px;
                padding-bottom: 16px;
                margin: 0px;
            }

            &--no-padding {
                padding: 0px;
            }

            &--comission {
                width: 100%;
                margin-top: 30px;
                padding-top: 28px;
                border-top: 1px solid #dde0e7;
            }
        }

        &__section-double {
            display: flex;
            justify-content: space-between;
            width: 500px;
        }

        &__col-double {
            width: 238px;
        }

        &__section-triple {
            display: flex;
            justify-content: space-between;
            width: 500px;
        }

        &__col-triple {
            width: 90px;
        }

        &__input {
            width: 100%;
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            margin: 8px 0px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;

            &:focus {
                border: 1px solid #00082c;
            }

            &:disabled {
                background: rgba(0, 0, 0, 0.2);
            }
        }

        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;

            &--checkbox {
                padding-left: 32px;
                z-index: 10;
                cursor: pointer;
                font-weight: normal !important;
            }

            &--normal {
                font-weight: normal !important;
            }
        }

        &__tip {
            font-weight: 300;
            font-size: 12px;
            line-height: 145%;
            color: #00082c;
        }

        &__point-status {
            display: flex;
            flex-direction: column;
            //margin-top: 32px;
            padding-bottom: 32px;
            //border-bottom: 1px solid #dde0e7;
            //margin-bottom: 24px;
        }

        &__checkbox-top {
            display: flex;
            align-items: center;
            margin-top: 12px;

            &--col {
                margin-bottom: 13px;
                align-items: flex-start;
            }
        }

        &__checkbox {
            width: 0px;
            height: 0px;
            display: none;

            &:checked ~ .edit-point-form__checkbox-mark {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:checked ~ .add-contact-form__label--checkbox {
                padding-left: 0px;
            }
        }

        &__checkbox-wrapper {
            width: 0px;
            height: 24px;
            position: relative;
            margin-left: 20px;

            &:before {
                content: "";
                background: #ffffff;
                border: 1px solid #d1d6df;
                box-sizing: border-box;
                box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
                border-radius: 4px;
                width: 24px;
                height: 24px;
                position: relative;
                display: block;
                top: 0px;
                left: 0px;
                opacity: 1;
            }
        }

        &__checkbox-mark {
            background: #00082c;
            border: 1px solid #00082c;
            box-sizing: border-box;
            box-shadow: 1px 1px 2px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            width: 24px;
            height: 24px;
            position: absolute;
            z-index: 5;
            display: none;
            top: 0px;
            left: 0px;
            opacity: 1;
        }

        &__buttons-bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            padding-bottom: 56px;
        }

        &__bottom-button {
            padding: 18px 40px 17px;
            width: 238px;
            height: 56px;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
            background: transparent;
            transition: all 0.2s ease-in-out;

            &:hover {
                background: #e8ebef;
            }

            &--red {
                border: 0px;
                background: #d80418;
                color: white;

                &:hover {
                    background: #ad0313 !important;
                }
            }
        }

        &__no-attachments {
            margin-bottom: 34px;
        }

        &__attachments {
            padding-top: 31px;
            border-top: 1px solid #dde0e7;
            margin-top: 26px;
        }

        &__attachments-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 27px;
        }

        &__upload-btn {
            display: flex;
            align-items: center;
            border: 0px;
            padding: 9px 16px;
            background: rgba(220, 228, 238, 0.56);
            border-radius: 4px;
        }

        &__upload-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;
            color: #00082c;
            white-space: nowrap;
            margin-left: 8px;
        }

        &__switch-section {
            display: flex;
            margin-bottom: 10px;
            padding-top: 10px;
        }

        &__tooltip {
            position: relative;
            left: 5px;
            top: -3px;
        }

        &__tip-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0px;
            background-color: transparent;
            width: 20px;
            height: 20px;
            position: relative;
            cursor: pointer;

            &:hover .hint {
                opacity: 1;
                display: block;
            }
        }
    }

    .file-input {
        display: none;
    }
</style>
