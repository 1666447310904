<template>
    <section class="contacts">
        <section class="contacts__head">
            <h1 class="contacts__head__title">Informator</h1>
            <section class="contact-top__action-buttons" v-if="annoAdminPrivilige">
                <ButtonWithIcon icon="plus-small" style=""
                                text="Dodaj komunikat"
                                :classModified="true"
                                @click="(e) => addAnno(null)" />
            </section>
        </section>

        <section class="contact-top">
            <section class="contact-top__search">
                <form class="contact-top__form">
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Wyszukaj komunikat'" />
                        <SearchInputTextComponent v-model="internalRequestQuery.searchText"
                                                  :placeholder="'Wyszukaj komunikat...'" />
                    </section>
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Data wysłania od'" />
                        <SearchInputDateComponent v-model="internalRequestQuery.dateFrom" />
                    </section>
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Data wysłania do'" />
                        <SearchInputDateComponent v-model="internalRequestQuery.dateTo" />
                    </section>
                    <section class="contact-top__form__item">
                        <SearchLabelComponent :label="'Wybierz grupę'" />
                        <section class="meetings-settings__wrapper" v-if="this.tempId == undefined || this.tempId == 0">
                            <select class="meetings-settings__select" v-model="internalRequestQuery.group">
                                <option v-for="s in groups" :key="s.id" :value="s.id"> {{s.nazwa}}</option>
                            </select>
                        </section>
                    </section>
                </form>
            </section>
        </section>
        <Pagination :totalItems="paginatedData.totalItems"
                    :currentPage="paginatedData.currentPage"
                    :itemsPerPage="paginatedData.itemsPerPage"
                    @goToPage="changePage" />
        <section class="contacts__list" v-if="anno.length > 0">
            <section class="contact-list">
                <section v-for="s in anno" :key="s.id">
                    <section class="contact-box">
                        <section class="contact-box__top" :class="{ 'color-box-important' : s.status == 4 }">
                            <section @click="openMessage(s.id)" style="cursor: pointer;">
                                <div>
                                    <span class="contact-box__title">Tytuł: <b>{{ s.title }}</b></span>
                                </div>
                                <span class="contact-box__title">Autor: <b>{{ s.author }}</b> (Dodano: {{formatDateToDisplay(s.addTime)}}) Pliki: ({{s.files.length}}) <b>{{s.status == 4 ? '| WAŻNE' : ''}}</b> </span>
                                <img src="@/assets/icons/panel/Contacts/list-arrow.svg"
                                     class="contact-box__arrow"
                                     :class="{ 'contact-box__arrow--open': s.show }" />

                            </section>
                            <section class="contact-box-actions">
                                <button class="defoult_button" @click="showAnnouncementLogs(s)">Logi</button>

                                <ButtonOnlyWithIcon icon="edit" @click="addAnno(s)" v-if="annoAdminPrivilige" />

                                <ButtonOnlyWithIcon icon="attachment" @click="addFile(s)" v-if="annoAdminPrivilige" />

                                <ButtonOnlyWithIcon icon="delete-point" @click="deleteAnnouncement(s.id)" v-if="annoAdminPrivilige" />
                            </section>
                        </section>
                        <section style="padding: 10px" class="contact-box__people contact-box__people--open" v-if="s.show">
                            <section v-if="s.title.length > 0">
                                <section class="contact-box__people-container">
                                    <h4>Odczytano ({{s.shows}})</h4>
                                    <p style="margin-top: 5px;" v-if="s.meetingDate != null"><b>Termin:</b> {{formatDateToDisplay(s.meetingDate)}}</p>
                                    <p style="margin-top: 5px;"><b>Ostatnia aktualizacja:</b> {{formatDateToDisplay(s.lastUpdateTime)}}</p>
                                    <p style="margin-top: 5px;"><b>Treść:</b> {{s.description}}</p>
                                    <div style="margin-top: 15px;" v-if="s.files.length > 0">
                                        <b>Pliki: </b>
                                        <section>
                                            <Attachemnt v-for="(attachment, index) in s.files"
                                                        :key="index"
                                                        :attachment="attachment"
                                                        :elemID="s.id"
                                                        @removeAttachement="removeFile"
                                                        @reloadAttachementName="reloadFileName" />
                                        </section>
                                    </div>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>

            </section>
        </section>
        <section class="contacts__list" v-else>
            <section class="contact-list">
                <section>
                    <section class="contact-box">
                        <section class="contact-box__top">
                            <section>
                                <span class="contact-box__title">Brak komunikatów spełniających podane kryteria</span>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
        <Pagination :totalItems="paginatedData.totalItems"
                    :currentPage="paginatedData.currentPage"
                    :itemsPerPage="paginatedData.itemsPerPage"
                    @goToPage="changePage" />

        <Transition>
            <AnnouncementModal v-if="showAnnouncementModal" @closeAnnoucement="closeAnnouncementModal" @updateCalendar="updateCommunicationList" :options="annoOptions" :options2="annoOptions2" :selected="selectedAnno" :selected2="selectedAnno2" :elemToUpdate="elemToUpdate" />
        </Transition>
        <Transition>
            <LogsModal v-if="showLogsModal" @closeLogs="closeLogsModal" :logs="logs" :basic="basic" />
        </Transition>
    </section>
</template>

<script charset="utf-8">
    import SearchLabelComponent from "@/components/BasicControls/SearchLabelComponent.vue";
    import SearchInputTextComponent from "@/components/BasicControls/SearchInputTextComponent.vue";
    import SearchInputDateComponent from "@/components/BasicControls/SearchInputDateComponent.vue";
    import Pagination from "@/components/BasicControls/Pagination.vue";
    import CommunicationService from "@/services/communication.service";
    import ContactService from "@/services/contact.service";
    import ButtonWithIcon from "@/components/BasicControls/ButtonWithIcon.vue";
    import ButtonOnlyWithIcon from "@/components/BasicControls/ButtonOnlyWithIcon.vue";
    import AnnouncementModal from "@/components/Panel/Content/Home/Modals/AddAnnouncement.vue";
    import LogsModal from "./Logs.vue";
    import Attachemnt from "./Attachment.vue";
    import { encryptStorage } from '@/services/encrypt.service';
    import CommonHelper from "@/helpers/common.helper";
    import uploaderService from "../../../../../services/uploader.service";
    import moment from "moment";
    import { uploaderConfig } from "@/config/uploader.file.config";

    export default {
        name: "announcement",
        data() {
            return {
                searchBar: "",
                anno: [],
                showAnnouncementModal: false,
                annoAdminPrivilige: encryptStorage.getItem('priv').informator,
                annoModule: encryptStorage.getItem('modules').inf,
                selectedAnno: [],
                selectedAnno2: [],
                annoOptions: [],
                annoOptions2: [],
                files: [],
                elemToUpdate: {},
                dateFilter: new Date(),
                showLogsModal: false,
                logs: [],
                basic: {},
                selected: 'Wszystkie grupy',
                groups: [],
                tempId: 0,
                page: 1,

                paginatedData: {
                    currentPage: -1,
                    itemsPerPage: 10,
                    totalItems: -1,
                },

                internalRequestQuery: {
                    searchText: null,
                    searchStatus: null,
                    dateFrom: null,
                    dateTo: null,
                    group: null,
                    page: 1,
                    itemsPerPage: 10,
                }, 

                isLoading: false,
                tempAnnouncementId: 0
            };
        },
        computed: {
            requestQuery() {
                return this.$route.query;
            },
            searchId() {
                if (this.$route.params.searchId === undefined || this.$route.params.searchId === null) {
                    return -1;
                }
                return parseInt(this.$route.params.searchId);
            },
            isAnyModalOpen(){
                return this.showLogsModal
                    || this.showAnnouncementModal;
            }
        },

        watch: {
            internalRequestQuery: {
                deep: true,
                handler: function (newValue) {
                    let queryParam = CommonHelper.getNonNullObjectEntries(newValue);
                    this.$router.replace({ name: "announcement", query: queryParam });
                    //this.fetchSmsData();
                    //this.fetchSessions(queryParam);
                }
            },
            $route() {
                if (this.$route.name === "announcement" && Object.keys(this.$route.query).length > 0) {
                    this.queryParamsToRequestSearch();
                    this.fetchAnnouncements();
                }

            },

            searchId: {
                deep: true,
                handler: function (newValue) {
                    try {
                        if (parseInt(newValue) === -1) {
                            return;
                        }
                        this.showSpecyficAnnouncement(newValue);
                    }
                    catch (e) {
                        console.warn(e);
                    }
                }
            }, 

            isAnyModalOpen: {
                deep: true,
                handler: function (val) {
                    document.querySelector('body').style.overflow = val ? 'hidden' : 'auto';
                }
            },
        },

        async mounted() {
            await this.internalFetch();
        },

        methods: {
            async internalFetch(){
                await this.fetchGroups();

                if (CommonHelper.isObjectIsEmpty(this.requestQuery)) {
                    if (this.$route.params.searchId != undefined && this.$route.params.searchId != null && this.$route.params.searchId > 0) {
                        await this.getSpecyficAnnouncement(parseInt(this.$route.params.searchId));
                    }
                    else {
                        this.setDefaultRequestQuery();
                    }
                }
                else {
                    this.queryParamsToRequestSearch();
                    await this.fetchAnnouncements();
                }
            },
            showSpecyficAnnouncement(id) {
                this.getSpecyficAnnouncement(id);
            },
            async fetchAnnouncements() {
                try {
                    this.isLoading = true;
                    this.$store.commit("showLoader");
                    let requestData = this.internalRequestDataToAsyncRequestData();
                    let response = await CommunicationService.searchAnnoucements(requestData);
                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }
                    this.anno = response.data.items;
                    this.paginatedData = response.data.paginated;
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading = false;
                    this.$store.commit("hideLoader");
                }
            },
            async getSpecyficAnnouncement(id) {
                try {
                    this.isLoading = true;
                    this.$store.commit("showLoader");
                    
                    let response = await CommunicationService.getSpecyficAnnoucement(id);
                    if (response.status !== 200) {
                        throw await Promise.reject(response)
                    }

                    this.anno = response.data;

                    this.paginatedData = {
                        currentPage: 1,
                        itemsPerPage: 10,
                        totalItems: 1,
                    };
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
                finally {
                    this.isLoading = false;
                    this.$store.commit("hideLoader");
                }
            },
            async fetchGroups() {
                try {
                    let groupsResponse = await CommunicationService.getGroups();
                    if (groupsResponse.status !== 200) {
                        throw await Promise.resolve(groupsResponse);
                    }
                    this.groups = groupsResponse.data;
                    var d = {
                        nazwa: 'Wszystkie grupy',
                        id: null,
                    }
                    this.groups.push(d);
                }
                catch (e) {
                    if (e.response.status == 404) {
                        this.$router.push({ name: 'notfoundpage', params: { pathMatch: 'sms' } });
                    }
                    else if (e.response.status == 401) {
                        this.$router.push({ name: 'Login' })
                    }
                }
            },

            internalRequestDataToAsyncRequestData() {
                return {
                    data: {
                        searchText: this.internalRequestQuery.searchText,
                        searchStatus: this.internalRequestQuery.searchStatu,
                        dateFrom: this.internalRequestQuery.dateFrom,
                        dateTo: this.internalRequestQuery.dateTo,
                        group: this.internalRequestQuery.group
                    },
                    paginated: {
                        page: this.internalRequestQuery.page,
                        itemsPerPage: this.internalRequestQuery.itemsPerPage,
                    }
                }
            },

            setDefaultRequestQuery() {
                this.clearRequestData();
                let queryParam = CommonHelper.getNonNullObjectEntries(this.internalRequestQuery);
                this.$router.replace({ name: "announcement", query: queryParam });
            },

            changePage(page) {
                this.internalRequestQuery.page = page;
            },
            clearRequestData() {
                this.internalRequestQuery.searchText = null;
                this.internalRequestQuery.searchStatus = null;
                this.internalRequestQuery.dateFrom = null;
                this.internalRequestQuery.dateTo = null;
                this.internalRequestQuery.group = null,
                this.internalRequestQuery.page = 1;
                this.internalRequestQuery.itemsPerPage = 10;
            },
            queryParamsToRequestSearch() {

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "searchText")) {
                    this.internalRequestQuery.searchText = this.requestQuery.searchText;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "dateFrom")) {
                    this.internalRequestQuery.dateFrom = this.requestQuery.dateFrom;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "dateTo")) {
                    this.internalRequestQuery.dateTo = this.requestQuery.dateTo;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "group")) {
                    this.internalRequestQuery.group = this.requestQuery.group;
                }

                if (CommonHelper.isObjectHasProperty(this.requestQuery, "_r")) {
                    this.clearRequestData();
                }
            },

            async addFile(announcement) {
                try {
                    this.$store.commit("showLoader");
                    let input = document.createElement('input');
                    input.type = 'file';
                    input.accept = uploaderConfig.announcement.allowedExtensions.join(",");
                    input.multiple = true;
                    input.click();
                    input.addEventListener('change', async (event) => {
                        try {
                            this.$store.commit("showLoader");
                            let response = await uploaderService.UploadAnnouncementAttachements(announcement?.id, event);

                            var annoUp = this.anno.find(x => x.id == announcement.id);
                            if (annoUp !== null) {
                                annoUp.files = annoUp.files?.concat([...response])
                            }
                        }
                        finally {
                            this.$store.commit("hideLoader");
                        }
                    });
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            removeFile(value) {
                var obj = this.anno.find(x => x.id == value.kid);
                if (obj != null) {
                    var att = obj.files.findIndex(x => x.id == value.fileId);
                    if (att != -1) {
                        obj.files.splice(att, 1);
                    }
                }
            },
            reloadFileName(value){
                var obj = this.anno.find(x => x.id == value.elemId);
                if (obj != null) {
                    var att = obj.files.findIndex(x => x.id == value.fileId);
                    if (att != -1) {
                        obj.files[att].name = value.name;
                    }
                }
            },
            async closeAnnouncementModal() {
                this.selectedAnno = [];
                this.selectedAnno2 = [];
                this.elemToUpdate = {};
                this.annoOptions = [];
                this.annoOptions2 = [];
                this.showAnnouncementModal = false;
                await this.internalFetch();
            },
            deleteAnnouncement(value) {
                const actionToFire = () => {
                    CommunicationService.deleteAnnouncement(value).then((result) => {
                        if (result.status == 200) {
                            var index = this.anno.findIndex(x => x.id == value);
                            if (index != -1) {
                                this.anno.splice(index, 1);
                            }
                        }
                    });
                };
                this.$store.commit("showConfirmationAlert", actionToFire);
            },
            async addAnno(value) {
                try {
                    this.$store.commit('showLoader');
                    var result = (await ContactService.getUsersToSmsOrEmail(3)).data;
                    if (Object.keys(result).length > 0) {
                        this.annoOptions = [];
                        this.annoOptions2 = [];

                        for (var i = 0; i < result.length; i++) {
                            var d = {
                                value: result[i].id,
                                label: result[i].label
                            }
                            this.annoOptions.push(d);

                            for (var j = 0; j < result[i].options.length; j++) {
                                if (this.annoOptions2.find(x => x.value == result[i].options[j].value) == null) {
                                    this.annoOptions2.push(result[i].options[j]);
                                }
                            }
                        }

                        this.selectedAnno = [];
                        this.selectedAnno2 = [];

                        if (value != null) {
                        
                            this.elemToUpdate = {};

                            if (value.groups != null)
                                this.selectedAnno = value.groups;
                            if (value.users != null)
                                this.selectedAnno2 = value.users;

                            this.elemToUpdate = {
                                id: value.id,
                                author: value.author,
                                description: value.description,
                                isInCalendar: value.isInCalendar,
                                meetingDate: value.meetingDate,
                                status: value.status,
                                title: value.title,
                                isEdit: true,
                                files: value.files,
                                addTime: value.addTime,
                                lastUpdateTime: value.lastUpdateTime,
                                shows: value.shows
                            }
                        } else {

                            var date = new Date();
                            var dat = moment(date).format("YYYY-MM-DD")

                            this.elemToUpdate = {
                                id: 0,
                                author: this.$store.state.userStore.userData.first_name + " " + this.$store.state.userStore.userData.last_name,
                                description: '',
                                isInCalendar: false,
                                meetingDate: dat,
                                status: 3,
                                title: '',
                                isEdit: false,
                                files: [],
                                addTime: null,
                                lastUpdateTime: null,
                                shows: 0
                            }
                        }
                        this.showAnnouncementModal = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally{
                    this.$store.commit('hideLoader');
                }
            },
            updateCommunicationList(value) {
                var checkUsersList = this.anno.findIndex(x => x.id == value.id);
                if (checkUsersList != -1) {
                    var data = {
                        id: value.id,
                        author: value.author,
                        description: value.description,
                        isInCalendar: value.isInCalendar,
                        meetingDate: value.meetingDate,
                        status: value.status,
                        title: value.title,
                        isEdit: true,
                        files: value.files,
                        addTime: value.addTime + "T" + value.addTimeHours,
                        lastUpdateTime: value.lastUpdateTime,
                        shows: value.shows,
                        users: value.users,
                        groups: value.groups
                    }
                    this.anno[checkUsersList] = data;
                }
                else {
                    //this.anno.unshift(value);
                    this.fetchAnnouncements();
                }
            },
            openMessage(id) {
                for (var i = 0; i < this.anno.length; i++) {
                    if (this.anno[i].id == id) {
                        if (this.anno[i].show == false)
                            this.anno[i].show = true;
                        else
                            this.anno[i].show = false;
                    }
                }
            },
            formatDateToDisplay(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                });

                return d;
            },
            async showAnnouncementLogs(value) {
                try {
                    this.$store.commit('showLoader');
                    var result = (await CommunicationService.showLogs(value.id)).data;
                    if (Object.keys(result).length > 0) {
                        this.logs = [];

                        for (var i = 0; i < result.length; i++) {
                            var temp = {
                                Użytkownik: this.createObj(result[i].user, result[i].user),
                                'Pierwszy odczyt': this.createObj(result[i].time != null ? this.formatDateToDisplay(result[i].time) : "0", result[i].time != null ? this.formatDateToDisplay(result[i].time) : "-"),
                                'Ostatni odczyt': this.createObj(result[i].lastTime != null ? this.formatDateToDisplay(result[i].lastTime) : "0", result[i].lastTime != null ? this.formatDateToDisplay(result[i].lastTime) : "-"),
                                Status: this.createObj(result[i].time != null ? 'odczytano' : 'nie odczytano', result[i].time != null ? 'odczytano' : 'nie odczytano')
                            };
                            this.logs.push(temp);
                        }

                        this.basic = {
                            addTime: value.addTime,
                            lastUpdateTime: value.lastUpdateTime,
                            title: value.title
                        };
                        this.showLogsModal = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally{
                    this.$store.commit('hideLoader');
                }
            },
            createObj(_raw, _parsed) {
                return {
                    raw: _raw,
                    parsed: _parsed
                };
            },
            closeLogsModal() {
                this.showLogsModal = false;
                this.logs = [];
                this.basic = {};
            },
            async nextPage() {
                this.page += 1;
                try {
                    this.anno = [];
                    this.$store.commit("showLoader");
                    this.anno = (await CommunicationService.getAllAnnoucement(this.page)).data;
                    for (var i = 0; i < this.anno.length; i++) {
                        this.anno[i].show = false;
                    }
                    this.$store.commit("hideLoader");
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async previousPage() {
                if (this.page > 1) {
                    this.page -= 1;
                    try {
                        this.anno = [];
                        this.$store.commit("showLoader");
                        this.anno = (await CommunicationService.getAllAnnoucement(this.page)).data;
                        for (var i = 0; i < this.anno.length; i++) {
                            this.anno[i].show = false;
                        }
                        this.$store.commit("hideLoader");
                    } catch (e) {
                        if (e.response.status == 401)
                            this.$router.push({ name: 'Login' })
                    }
                }
            }
        },
        components: {
            ButtonWithIcon,
            AnnouncementModal,
            ButtonOnlyWithIcon,
            Attachemnt,
            LogsModal,
            Pagination,
            SearchInputTextComponent,
            SearchInputDateComponent,
            SearchLabelComponent
        },
        
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/search-component.scss";
    .defoult_button {
        width: 50px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #bbc2ce;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #e8ebef;
        }
    }

    .color-box-important {
        border-radius: 12px;
        border: 2px solid red;
    }

    .meetings-settings {
        margin-top: 20px;

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        &__wrapper {
            position: relative;
            /*&::after {
                content: "";
                position: absolute;
                top: 8px;
                background: #e2e5eb;
                width: 1px;
                height: 24px;
                right: 40px;
                z-index: 99;
            }*/
        }

        &__select {
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            padding: 8px 10px 8px 16px;
            font-weight: normal;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url("../../../../../assets/icons/components/select-arrow.svg");
            background-repeat: no-repeat;
            background-position-x: calc(100% - 14px);
            background-position-y: 50%;
            min-width: 300px;
            min-height: 48px;

            &:focus {
                outline: none;
            }
        }
    }


</style>
