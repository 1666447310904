<template>
    <section class="login">
        <section></section>
        <section class="login__form-wrapper">
            <eSesjaLogo class="login__logo" />
            <form class="login-form">
                <h1 class="login-form__header">Wylogowano</h1>
                <p class="login-form__text">
                    Wylogowano pomyślnie z systemu eSesja
                </p>
                <br />
                <button class="login-form__login-btn" @click="handleGoToLoginPage">
                    Zaloguj ponownie
                </button>
            </form>
        </section>
        <eSesjaFooter /> 
    </section>
</template>

<script charset="utf-8">
    import eSesjaFooter from "../components/Shared/eSesjaFooter.vue";
    import eSesjaLogo from "../components/Shared/eSesjaLogo.vue";

    export default {
        name: "LoggedoutComponent",
        data() {
            return {
                link: ''
            };
        },
        components: {
            eSesjaFooter,
            eSesjaLogo
        },
        mounted() {
            if (this.$route.params.link == undefined || this.$route.params.link == null || this.$route.params.link == 'Login') {
                this.link = 'Login';
            }
            else {
                this.link = window.location.protocol + "//" + window.location.host + this.$route.params.link;
            }
        },
        methods: {
            handleGoToLoginPage: function (e) {
                e.preventDefault();

                if (this.link == 'Login')
                    this.$router.push({ name: this.link });
                else {
                    window.open(this.link, "_self");
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
.login {
    width: 100vw;
    height: 100vh;
    background: radial-gradient( 235.38% 418.45% at 0% 0%, #00082c 0%, #004dcc 100% );
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-family: Inter;
    position: relative;

    &__logo {
        margin-bottom: 26px;
    }
}

.login-form {
    padding: 48px 40px 56px 40px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(146.23deg, #d5e0f5 0%, #ffffff 100%);
    border: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
    border-radius: 12px;

    &__header {
        text-align: center;
        color: #00082c;
        font-size: 28px;
        font-weight: 600;
    }

    &__text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        margin-top: 12px;
        color: #333956;
    }

    &__login-btn {
        background-color: #d80418;
        padding: 18px 0px;
        color: white;
        border: 0px;
        font-weight: 500;
        font-size: 16px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #ad0313;
        }
    }
}

</style>
