export const settingsStore = {
    state: () => ({
        client: {
            chartType: 0,
            cameraApiAddress: 'localhost',
            autoEndVotingAfterAllVoted: false,
            autoGoToNextVotingAfterSave: false,
            autoStartNextVoting: false,
            autoStartNextVotingTime: 3,
            menuInteractiveShowQorum: true,
            interactiveModalVotingShowAvatar: true,
            interactiveModalQuorumShowAvatar: true,
            interactiveDiscussionShowAvatar: true,
            clientLogoShowOw: true,
            clientLogoShowDocuments: true,
            clientFooterShowDocuments: true,
        },
        allowIpAddress: '',
        logoBase64: '',
        cadenceYears: '-'
    }),
    mutations: {
        setClientSettings(state, status) {
            state.client = status;
        },
        setVotingsChartType(state, status) {
            state.client.chartType = status;
        },
        seCameraApiAddress(state, status) {
            state.client.cameraApiAddress = status;
        },
        setAllowIpAddress(state, ipAddress){
            state.allowIpAddress = ipAddress
        },
        setLogoBase64(state, status) {
            state.logoBase64 = status;
        },
        setCadenceYears(state, status) {
            state.cadenceYears = status;
        },
    }
}