import { createRouter, createWebHistory } from "vue-router";
import { encryptStorage } from '../../src/services/encrypt.service';
import customCssService from "../services/custom.css.service";
import Login from "@/views/Login.vue";
import Loggedout from "@/views/Loggedout.vue";
//import LoginADFS from "@/views/LoginADFS.vue";
import ADFS from "@/views/Adfs.vue";
import Panel from "@/views/Panel.vue";
import PublicSession from "@/views/PublicSession.vue";
import PublicSessionInfo from "@/views/PublicSessionInfo.vue";
import NotFound from "@/views/NotFound.vue";
import Home from "@/components/Panel/Content/Home/Home.vue";
import EditMeetingPoints from "@/components/Panel/Content/SessionPoints/EditMeetingPoints.vue";
import SessionSearch from "@/components/Panel/Content/SessionPoints/SessionSearch.vue";
import EditMeetingTemplatePoints from "@/components/Panel/Content/SessionPoints/EditMeetingTemplatePoints.vue";
import Kontakt from "@/components/Panel/Content/Contacts/Contacts.vue";
import Sms from "@/components/Panel/Content/Communication/SMS/Sms.vue";
import Email from "@/components/Panel/Content/Communication/Email/Email.vue";
import News from "@/components/Panel/Content/Communication/News/News.vue";
import Announcements from "@/components/Panel/Content/Communication/Announcements/Announcements.vue";
import UserActivity from "@/components/Panel/Content/Tools/UserActivity.vue";
import UsersPresence from "@/components/Panel/Content/Tools/UsersPresence.vue";
import Documents from "@/components/Panel/Content/Tools/Documents.vue";
import Register from "@/components/Panel/Content/Register/Reg/Register.vue";
import Interpellation from "@/components/Panel/Content/Interpellations/Interpellation.vue";
import Profile from "@/components/Panel/Content/User/Profile.vue";
import VersionsHistory from "@/components/Panel/Content/Tools/VersionsHistory.vue";
import ClientSettings from "@/components/Panel/Content/Settings/ClientSettings.vue";
import UserEmailConfirmation from "@/components/Panel/Content/User/UserEmailConfirmation.vue";
import UserForgotPassword from "../components/Panel/Content/User/UserForgotPassword.vue";
import UserResetPassword from "../components/Panel/Content/User/UserResetPassword.vue";

// wizards
//import Wizards from "../views/Wizards.vue";
//import NewCadenceWizards from "../components/Wizards/NewCadence/NewCadenceWizards.vue";
//import NewCadenceWizardsSteps from "../components/Wizards/NewCadence/NewCadenceWizardsSteps.vue";

// interactive
import InteractiveView from "../views/InteractiveView.vue";
import InteractiveDiscussion from "../components/Panel/Interactive/InteractiveDiscussion.vue";
import DiscussionQuestList from "../components/Panel/Interactive/Discussion/DiscussionQuestList.vue";

// attachment
import AttachmentInteractive from "../components/Panel/Content/Attachment/AttachmentInteractive.vue";
import AttachmentViewer from "../components/Panel/Content/Attachment/AttachmentViewer.vue";

// authenticate
import AuthenticateView from "../views/AuthenticateView.vue";
import MwcView from "../components/Panel/Authenticate/MwcView.vue";

import CalendarView from "../views/CalendarView.vue";

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
    },
    {
        path: "/loggedout/:link",
        name: "LoggedoutComponent",
        component: Loggedout,
    },
    //{
    //    path: "/adfs-login",
    //    name: "ADFSLogin",
    //    component: LoginADFS,
    //},
    {
        path: "/adfs",
        name: "ADFS",
        component: ADFS,
    },
    {
        path: "/PublicSession/:code",
        name: "PublicSession",
        component: PublicSession,
    },
    {
        path: "/PublicSessionInfo/:code",
        name: "PublicSessionInfo",
        component: PublicSessionInfo,
    },
    {
        path: "/user/email-confirm/:token",
        name: "UserEmailConfirm",
        component: UserEmailConfirmation,
    },
    {
        path: "/user/forgot-password",
        name: "UserForgotPassword",
        component: UserForgotPassword,
    },
    {
        path: "/user/reset-password/:token",
        name: "UserResetPassword",
        component: UserResetPassword,
    },
    {
        path: "/app",
        name: "Panel",
        component: Panel,
        children: [
            {
                path: "home",
                component: Home,
                name: "home"
            },
            {
                path: "sessions/:sessionid/:groupid",
                name: "sessionEdit",
                component: EditMeetingPoints,
            },
            {
                path: "sessions/template/edit/:sessionid",
                name: "sessionTemplateEdit",
                component: EditMeetingTemplatePoints,
            },
            {
                path: "sessions/search",
                name: "sessionSearchRoute",
                component: SessionSearch,
            },
            {
                path: "kontakty",
                component: Kontakt,
                name: "contact"
            },
            {
                path: "interpellations",
                component: Interpellation,
                name: "interpellations"
            },
            {
                path: "sms",
                component: Sms,
                name: "sms",
            },
            {
                path: "email",
                component: Email,
                name: "email",
            },
            {
                path: "news",
                component: News,
                name: "news",
            },
            {
                path: "rejestr",
                component: Register,
                name: "rejestr",
                props: true
            },
            {
                path: "announcements/:searchId?",
                component: Announcements,
                name: "announcement"
            },
            {
                path: "aktywnosc",
                component: UserActivity,
                name: "activity"
            },
            {
                path: "dokumenty",
                component: Documents,
                name: "doc",
            },
            {
                path: "raport",
                component: UsersPresence,
                name: "presence"
            },
            {
                path: "user/profile",
                component: Profile,
                name: "user-profile"
            },
            {
                path: "versions/history",
                component: VersionsHistory,
                name: "versions-history"
            },
            {
                path: "client/settings",
                component: ClientSettings,
                name: "client-settings"
            },
            {
                path: "calendar",
                component: CalendarView,
                name: "app-new-calendar"
            }
        ],
    },
    //{
    //    path: "/wizards",
    //    name: "Wizards",
    //    component: Wizards,
    //    children: [
    //        {
    //            path: "new-cadence",
    //            name: "NewCadenceWizards",
    //            component: NewCadenceWizards,
    //            children: [
    //                {
    //                    path: "steps/:step",
    //                    name: "NewCadenceWizardsSteps",
    //                    component: NewCadenceWizardsSteps
    //                }
    //            ]
    //        }
    //    ]
    //},
    {
        path: "/interactive",
        name: "InteractiveView",
        component: InteractiveView,
        children: [
            {
                path: "discussion",
                name: "InteractiveDiscussion",
                component: InteractiveDiscussion,
                children: [
                    {
                        path: "guest-list/:sessionId",
                        name: "DiscussionQuestList",
                        component: DiscussionQuestList,
                    }
                ]
            },
            {
                path: "attachment",
                name: "AttachmentInteractive",
                component: AttachmentInteractive,
                children: [
                    {
                        path: "viewer/:attachmentId",
                        name: "AttachmentViewer",
                        component: AttachmentViewer
                    }
                ]
            }
        ]
    },
    {
        path: "/authenticate",
        name: "Authenticate",
        component: AuthenticateView,
        children: [
            {
                path: "mwc/:code(.*)",
                name: "AuthenticateMwc",
                component: MwcView
            }
        ]
    },
    {
        path: '/:pathMatch(.*)',
        component: NotFound,
        name: "notfoundpage"
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    // try to access a restricted page + not logged in
    const publicPages = ["/"];
    const authRequired = !publicPages.includes(to.path);
    const token = encryptStorage.getItem("token");

    const public2 = ["/PublicSession", "/PublicSessionInfo", "/user/email-confirm", "/user/forgot-password", "/user/reset-password", "/adfs", "/authenticate", "/public/attachment/viewer", "/loggedout"];

    const pb = public2.some(e => { return to.path.includes(e) && e !== "/" });
    
    await customCssService.injectCss();

    if (to.path === "/" && token) {
        return await next("/app/home");
    }

    if (authRequired && !token && !pb) {
        return await next("/");
    }
    return await next();
});

export default router;
