<template>
    <section class="esesja-modal send-meeting-regarding-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Edytor zaproszeń</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$store.commit('setPrepareMeetingInvitationModalStatus', false);}">
                    <img src="@/assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <label class="esesja-modal__wraper__body__label">Adresaci - ilość zaproszeń imiennych do wygenerowania ({{selectedUsers.length}})</label>
                <section class="esesja-modal__row esesja-modal__row__as-column">
                    <Multiselect ref="searchUsers"
                                 v-model="selectedUsers"
                                 mode="tags"
                                 placeholder="Wyszukaj..."
                                 :close-on-select="false"
                                 :searchable="true"
                                 class="esesja-multiselect"
                                 noResultsText="brak wyników"
                                 noOptionsText="brak opcji"
                                 :groups="true"
                                 :options="availableUsers"
                                 :searchFilter="customSearchFilter"
                                 @select="selectedOptions" />
                </section>

                <label class="esesja-modal__wraper__body__label">Sesja - wybrana Sesja do nagłówka zaproszenia</label>
                <section class="esesja-modal__row">
                    <select class="esesja-modal__wraper__body__input" v-model="selectedSession" @change="checkSelectedSession($event)">
                        <option v-for="s in sessionsToSelect" :value="s.id" :key="s.id"> {{showSessionName(s)}} {{showDate(s.meeting_date)}}</option>
                    </select>
                </section>

                <label class="esesja-modal__wraper__body__label">Wstęp do zaproszenia</label>
                <section class="esesja-modal__row">
                    <ckeditor :editor="editor" :config="{ removePlugins: ['MediaEmbed', 'EasyImage', 'ImageUpload'], mediaEmbed: {} }" v-model="header"></ckeditor>
                </section>

                <label class="esesja-modal__wraper__body__label">Treść główna zaproszenia</label>
                <section class="esesja-modal__row">
                    <ckeditor :editor="editor" :config="{ removePlugins: ['MediaEmbed', 'EasyImage', 'ImageUpload'], mediaEmbed: {} }" v-model="body"></ckeditor>
                </section>

                <label class="esesja-modal__wraper__body__label">Stopka zaproszenia</label>
                <section class="esesja-modal__row">
                    <ckeditor :editor="editor" :config="{ removePlugins: ['MediaEmbed', 'EasyImage', 'ImageUpload'], mediaEmbed: {} }" v-model="footer"></ckeditor>
                    <section style="font-size: 11px;">*Herb/Logo oraz stopka graficzna zostaną automatycznie dodane do dokumentu na podstawie zakładki Ustawienia</section>
                </section>

                <section class="esesja-modal__row">
                    <p>
                        <img src="../../../assets/icons/components/tooltip.svg" style="max-width: 20px; margin-right: 5px;" />
                        <span class="tooltip" @mouseover="showImageModal = true" @mouseout="showImageModal = false">Zobacz schemat generowanego dokumentu</span>
                    </p>
                </section>

                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button" @click="() => { $store.commit('setPrepareMeetingInvitationModalStatus', false); }">Zamknij</button>
                    <button class="esesja-modal__wraper__body__bottom-button esesja-modal__wraper__body__bottom-button--red" @click="downloadInvitationFile" :disabled="selectedUsers.length <= 0">Pobierz (.docx)</button>
                </section>
            </perfect-scrollbar>
        </section>

        <ShowImageModal v-if="showImageModal" :imgContent="imgContent" />

    </section>
</template>
<script charset="utf-8">
    // services
    import AlertHelper from "../../../helpers/alerts.helper";
    import ContactService from "../../../services/contact.service";
    import SessionService from "../../../services/session.service";
    import UserService from "../../../services/user.service";
    import moment from "moment";
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import ShowImageModal from '../Session/ShowImageModal.vue'
    // Components
    import Multiselect from '@vueform/multiselect'
    import PointsNumerationHelper from "@/helpers/pointsNumeration.helper";
    import {
        switchToRoman,
        switchIndexToLetter,
    } from "../../../helpers/index.conversion";

    export default {
        name: "PrepareMeetingInvitation",
        components: {
            Multiselect,
            ckeditor: CKEditor.component,
            ShowImageModal,
        },
        data() {
            return {
                showImageModal: false,
                imgContent: require('@/assets/imgs/schemat_zaproszenia_2.png'),
                editor: ClassicEditor,
                selectedUsers: [],
                body: '',
                header: '',
                footer: '',
                availableUsers: [],
                sessionData: {
                    name: "",
                    meeting_date: "",
                    groups: [],
                },
                sessionDataLink: '',
                errors: [],
                sessionsToSelect: [],
                selectedSession: 0,
                firstLvl: 1,
                secondLvl: 2,
                thirdLvl: 3,
            }
        },
        async mounted() {
            try {
                this.$store.commit("showLoader");
                await this.internalFetchSessionsList();
                await this.internalFetchAvailableUsers();
                await this.internalFetchSessionData();
                await this.internalFetchUserInvitationData();
            }
            finally {
                this.$store.commit("hideLoader");
            }
        },
        methods: {
            numberConversion(index, default_session_number_format) {
                switch (default_session_number_format) {
                    case 1:
                        return `${switchToRoman(index)}`;
                    case 2:
                        return `${index}.`;
                    case 3:
                        return `${index})`;
                    case 4:
                        return `${switchIndexToLetter(index)})`;
                    default:
                        return `${index}.`;
                }
            },
            checkSelectedSession() {
                this.$store.commit("showLoader");
                SessionService.getPoints(this.selectedSession).then((response) => {
                    this.prepareAllPointsForSelect(response.data);
                    this.$store.commit("hideLoader");
                }).catch((e) => {
                    console.log(e);
                    this.$store.commit("hideLoader");
                });
            },
            prepareAllPointsForSelect(list) {
                var tempPoints = [];
                var pointsToSelect_temp = list.filter(x => x.is_hidden == false);
                var pointsToSelect = PointsNumerationHelper.setOrderToDisplayRecursive([...pointsToSelect_temp])

                // prepare agenda groups points
                var session_group_data = this.sessionData.groups;
                if (session_group_data != undefined && session_group_data != null && session_group_data.length > 0) {
                    this.firstLvl = session_group_data[0].agenda_number_format_1_id;
                    this.secondLvl = session_group_data[0].agenda_number_format_2_id;
                    this.thirdLvl = session_group_data[0].agenda_number_format_3_id;
                }

                for (var i = 0; i < pointsToSelect.length; i++) {
                    tempPoints.push(this.filterIndex(pointsToSelect[i], 0) + " " + pointsToSelect[i].name);

                    if (pointsToSelect[i].children != null && pointsToSelect[i].children.length > 0) {
                        for (var j = 0; j < pointsToSelect[i].children.length; j++) {

                            tempPoints.push('\xA0\xA0\xA0 ' + this.filterIndex(pointsToSelect[i].children[j], 1) + " " + pointsToSelect[i].children[j].name);

                            if (pointsToSelect[i].children[j].children != null && pointsToSelect[i].children[j].children.length > 0) {
                                for (var k = 0; k < pointsToSelect[i].children[j].children.length; k++) {
                                    tempPoints.push('\xA0\xA0\xA0\xA0\xA0\xA0 ' + this.filterIndex(pointsToSelect[i].children[j].children[k], 2) + " " + pointsToSelect[i].children[j].children[k].name);
                                }
                            }
                        }
                    }
                }

                this.body = '';

                var body = '';
                for (var v = 0; v < tempPoints.length; v++) {
                    body += tempPoints[v] + '<br>';
                }

                this.body += body;
            },
            filterIndex(elem, lvl) {
                return PointsNumerationHelper.filterIndex(elem.orderToDisplay, elem.number, lvl, this.firstLvl, this.secondLvl, this.thirdLvl);
            },
            async internalFetchSessionsList() {
                try {
                    let result = await SessionService.getAllSessionsYear();
                    if (result.status !== 200) {
                        throw await Promise.reject(result);
                    }

                    this.sessionsToSelect = result.data;
                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            showSessionName(session) {

                var name = session.name;
                var number = session.number;
                var group = session.default_group_name;
                var nameToDisplay = '';

                if (name.length > 0 && group.length == 0) {
                    nameToDisplay = name;

                    if (number != null && number > 0) {
                        nameToDisplay += " nr " + this.numberConversion(number, session.default_session_number_format);
                    }
                }
                else if (name.length > 0 && group.length > 0) {
                    nameToDisplay = name;

                    if (number != null && number > 0) {
                        nameToDisplay += " nr " + this.numberConversion(number, session.default_session_number_format);
                    }

                    nameToDisplay += " (" + group + ")";
                }
                else {
                    nameToDisplay = 'Posiedzenie ';

                    if (number != null && number > 0) {
                        nameToDisplay += " nr " + this.numberConversion(number, session.default_session_number_format);
                    }

                    nameToDisplay += " (" + group + ")";
                }

                return nameToDisplay;
            },
            showDate(date) {
                if (date != null && date != undefined && date.length > 0)
                    return "z dnia " + this.formatDateToDisplay(date);
            },
            formatDateToDisplay(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });

                return d;
            },
            formatDateToDisplayLong(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    weekday: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                });

                return d;
            },
            customSearchFilter(option, query) {
                let localQuery = query.toUpperCase();
                let localcopy = [...this.availableUsers];
                // check if any groups meets criteria
                let filterGroup = localcopy.filter(item => {
                    let copyGroupLabel = item.label.toUpperCase()
                    return copyGroupLabel.includes(localQuery);
                })

                if (filterGroup.length > 0) {
                    let isOptionIsInGroup = filterGroup.filter(item => item.id === option.groupId);
                    return isOptionIsInGroup.length > 0;
                }
                return option.label.toUpperCase().includes(localQuery);
            },
            selectedOptions() {
                this.$refs.searchUsers.clearSearch();
            },
            async internalFetchAvailableUsers() {
                try {
                    let result = await ContactService.getUsersToSmsOrEmail(3);
                    if (result.status !== 200) {
                        throw await Promise.reject(result);
                    }

                    this.availableUsers = [ ... result.data]

                    this.availableUsers = this.availableUsers.map(item => ({
                        id: item.id,
                        label: item.label,
                        options: item.options.map(opt => ({
                            value: opt.value,
                            label: opt.label,
                            groupId: item.id
                        }))
                    }));
                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },

            async internalFetchSessionData() {
                try {
                    let result = await SessionService.getSession(this.$store.state.modals.sessions.prepareMeetingInvitationModalSessionId)

                    if (result.status !== 200) {
                        throw await Promise.reject(result);
                    }

                    this.sessionData = result.data;
                    this.selectedSession = result.data.id;
                    this.checkSelectedSession();
                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },

            async internalFetchUserInvitationData() {
                try {
                    let result = await UserService.getAdminUserInvitationData()

                    if (result.status !== 200 && result.status !== 204) {
                        throw await Promise.reject(result);
                    }

                    if (result.data != null) {

                        if (result.data.header != undefined && result.data.header != null) {
                            this.header = result.data.header;
                        }
                        else {
                            this.header = '';
                        }

                        if (result.data.footer != undefined && result.data.footer != null) {
                            this.footer = result.data.footer;
                        }
                        else {
                            this.footer = '';
                        }
                    }
                }
                catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },

            async downloadInvitationFile() {
                try {
                    this.$store.commit("showLoader");

                    var data = {
                        sessionId: this.selectedSession,
                        body: this.body,
                        header: this.header,
                        footer: this.footer,
                        peopleList: this.selectedUsers
                    };

                    let response = await SessionService.createExportedInvitationToWord(this.selectedSession, data);

                    if (response.status !== 200) {
                        throw await Promise.reject(response);
                    }

                    const contentType = response.headers['content-type'];
                    let a = document.createElement('a');
                    var blob = new Blob([response.data], { 'type': contentType });
                    a.href = window.URL.createObjectURL(blob);
                    var filename = response.headers['content-disposition'].split("filename=")[1];

                    filename = filename.substr(0, filename.indexOf(';'));
                    filename = filename.replaceAll('"', '');
                    a.download = filename
                    a.click();

                    //this.$store.commit('setPrepareMeetingInvitationModalStatus', false);
                    this.$swal(AlertHelper.successAlert("Zaproszenie zostało pobrane"));
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            humanDate(date) {
                return moment(date).format("DD MMMM YYYY");
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/esesja-modal.scss';

    .tooltip {
        cursor: pointer;
        font-size: 14px;
    }

    .send-meeting-regarding-modal {
        .esesja-modal__wraper {
            max-height: 90vh;
            min-width: 55vw;
        }

        .attached-file-wrapper {
            display: flex;
            flex-direction: row;
            gap: 10px;
            flex-wrap: wrap;

            &__item {
                display: flex;
                background: #e8ecf2;
                border-radius: 20px;
                padding: 10px 20px;
                flex-direction: row;
                align-content: center;
                align-items: center;
                gap: 5px;
                border: 1px solid transparent;
                transition: all 0.2s ease-in-out;


                &__remove {
                    opacity: 0;
                    transition: all 0.2s ease-in-out;
                }

                &:hover {
                    border-color: #bbc2ce;

                    .attached-file-wrapper__item__remove {
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }
        }

        .esesja-modal__row--to-right {
            justify-content: flex-end;
            align-items: flex-end;
        }

        .esesja-modal__wraper__body__input__file {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            padding-left: 0px !important;


            &::file-selector-button {
                background: #fff;
                padding: 12.5px 15px;
                border: none;
                transition: all 0.2s ease-in-out;
                cursor: pointer;

                &:hover {
                    background: #e8ebef;
                }
            }
        }

        .esesja-modal__row__as-column {
            flex-direction: row;
            column-gap: 10px;

            .esesja-modal__wraper__body__bottom-button {
                padding: 18px 15px 17px;
            }
        }
    }
   
</style>