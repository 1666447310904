<template>
    <section class="points-list"
             :class="{ 
                'points-list--no-children': !haveChildren && level > 1,
            }">
        <draggable class="dragArea"
                   tag="ul"
                   :list="items"
                   :disabled="session.is_end == true || isAnyModalIsOpened == true"
                   animation="500"
                   easing="cubic-bezier(1, 0, 0, 1)"
                   :class="{ 'max-stack': level === 2 }"
                   :ghost-class="level > 0 ? 'ghost' : 'ghost--first-level'"
                   @refreshList="this.$emit('refreshList')"
                   @reloadData="this.$emit('reloadData')"
                   :group="
                   {
                   name: 'g1' ,
                   put: (to, from, element, event)=>
            canBePlaced(to, from, element, event),
            }"
            :drag-class="level > 0 ? 'dragged-item' : 'dragged-item--first-level'"

            @setPointIds=" (value) => {
            this.point_ids = value;
            this.$emit('setPointIds', value);
            }">
            <li v-for="(item, index) in items"
                :id="getPointUniqueId(item)"
                :key="(index += 1)"
                :class =
                "{
                    'no-drag children children--last': level === 2 ,
                    'children': level > 0,
                    'has-children': level < 2 && item.children.length > 0,
                    'has-max-children': childrenCheck(item.children) === 2,
                    'session-point--selected': this.point_ids.indexOf(item.id) !== -1 && level === 0,
                    'session-point--hide' : item.is_hidden && level === 0,
                    'session-point--active' : item.is_active && level === 0,
                    'has-no-active-children': (item.child_is_active === false && item.is_active === false) && isInteractive()
                }"
                class="session-point"
                >
                <section class="points-list__data-wrapper">
                    <img :src="
                            index === 1
                                ? require('../../../../assets/icons/panel/editSession/last-element-arrow.svg')
                                : require('../../../../assets/icons/panel/editSession/next-point-arrow.svg')
                        "
                         v-if="level === 2"
                         class="points-list__arrow"
                         :class="{ 'points-list__arrow--next': index > 1 }" />
                    <section class="points-list__point-box"
                             :class=
                             "{
                                'points-list__point-box--no-border' : level === 0,
                                'last-element' : level === 2,
                                'points-list__point-box--selected' : this.point_ids.indexOf(item.id) !== -1 && level > 0,
                                'points-list__point-box--hide' : item.is_hidden && level > 0,
                                'points-list__point-box--active' : item.is_active && level> 0
                            }"
                        @contextmenu="openContextMenu">
                        <AgendaItemContextMenu @addAttachment="() => { this.itemId = item.id; handleFileUpload(); }"
                                               @addAttachmentRepository="(element) => handleAddAttachementFromRepositoryClick(element, false)"
                                               @addResolution="() => { this.itemId = item.id; this.isResolution = true; handleFileUpload(); }"
                                               @addResolutionFromRepository="(element) => handleAddAttachementFromRepositoryClick(element, true)"
                                               @addNote="(element) => { handleAddNoteClick(element, null, false); closeContextMenu()}"
                                               @addlink="(element) => { handleAddLinkClick(element, null, false); closeContextMenu()}"
                                               @addStatement="(element) => { handleaddStatementClick(element); closeContextMenu()}"
                                               @pointIsDone="(element) => { handleaddIsDoneClick(null, element); closeContextMenu()}"
                                               @pointIsHide="(element) => { handleaddIsHideClick(null, element); closeContextMenu()}"
                                               @pointIsTakenOff="(element) => { handleaddIsTakenOffClick(null, element); closeContextMenu()}"
                                               @activePoint="(element) => { handleSetActivePointClick(null, element); closeContextMenu()}"
                                               @pointBlockVotingsResults="(element) => { handleaddPointBlockVotingsResultsClick(element); closeContextMenu()}"
                                               @addVottingFromMenu="(element) => { handleCreateVotingClick(element, null); closeContextMenu()}"
                                               @editSessionPointFromMenu="(element) => { handleEditPointClick(element); closeContextMenu()}"
                                               :item="item"
                                               :isTemplate="isTemplate"
                                               :isInteractive="isInteractive()"
                                               :isHide="item.is_hidden"
                                               :isEnd="item.is_done"
                                               :isTakenOff="item.is_taken_off"
                                               :isActive="item.is_active"
                                               :canRegistry="checkCanRegistry()"
                                               :isVotingResults="isSomeVotingsResultsIsPoint(item)" />
                        <section class="points-list__point-box-left">
                            <section>

                                <section class="points-list__checkbox">

                                    <CheckBoxButton v-if="session.is_end == false" :value="item.id" :id="item.id" :checked="point_ids.indexOf(item.id) !== -1" :idsArray="point_ids"
                                                    @setGroupId="(value)=>{this.point_ids = value;this.$emit('setPointIds',value); } " />

                                    <label class="points-list__checkbox-label" :for="item.id"></label>
                                    <span class="points-list__number" :class="{ 'points-list__name--bold' : level === 0,}">{{ item.is_hidden ? '-' : filterIndex(item.orderToDisplay, item.number) }}</span>
                                    <span class="points-list__name" :class="{'points-list__name--bold' : level === 0, 'points-list__name--pointtakenoff' : item.is_taken_off }"> {{ item.is_hidden ? '(ukryty) ' + item.name : item.name }} </span>
                                </section>
                            </section>

                            <PointInfoPart :item="item"></PointInfoPart>

                            <section class="points-list__attachments">
                                <attachmentDragable class="points-list__attachments__drag-attachment"
                                                    tag="ul"
                                                    v-model="item.attachments"
                                                    item-key="order"
                                                    animation="500"
                                                    easing="cubic-bezier(1, 0, 0, 1)"
                                                    :disabled="session.is_end == true || isAnyModalIsOpened == true"
                                                    :group="{name: 'attachemntSameGroup', put: false, pull: false}"
                                                    @end="endAttachemntOrder(item.attachments)">
                                    <li :draggable="true" class="points-list__attachment-wrapper" v-for="( attachment, index ) in item.attachments" :key="index">
                                        <img src="../../../../assets/icons/components/attachment.svg"
                                             v-if="attachment.type_id !== 7 && attachment.type_id !== 14 && attachment.type_id !== 1" />
                                        <img src="../../../../assets/icons/components/attachment-red.svg"
                                             v-else-if="attachment.type_id !== 7 && attachment.type_id !== 14 && attachment.type_id == 1" />
                                        <img src="../../../../assets/icons/components/note.svg"
                                             v-else />

                                        <a :class="attachment.public ? 'points-list__attachment-link__public' : 'points-list__attachment-link'"
                                           v-if="attachment.type_id === 7 && attachment.user_id === currentUserLid"
                                           @click="handleShowNoteClick(item.id, attachment.id)" v-tooltip.bottom="attachment.public ? 'Pokaż notatkę (publiczna)' : 'Pokaż notatkę (niepubliczna)'">
                                            {{ attachment.name }}
                                        </a>
                                        <a :class="attachment.public ? 'points-list__attachment-link__public' : 'points-list__attachment-link'"
                                           v-else-if="attachment.type_id === 7"
                                           @click="handleShowNoteClick(item.id, attachment.id)" v-tooltip.bottom="attachment.public ? `Pokaż notatkę administratora ${getUserName(attachment.user_id)} (publiczna)` : `Pokaż notatkę administratora ${getUserName(attachment.user_id)} (niepubliczna)`">
                                            {{ attachment.name }}
                                        </a>

                                        <a :class="attachment.public ? 'points-list__attachment-link__public' : 'points-list__attachment-link'"
                                           v-else-if="attachment.type_id === 6"
                                           @click="handleGoToLinkClick(attachment.url)" v-tooltip.bottom="tooltipForAttachementText(attachment)">
                                            {{ attachment.name }}
                                        </a>
                                        <a :class="attachment.public ? 'points-list__attachment-link__public' : 'points-list__attachment-link'"
                                           v-else
                                           @click="openAttachmentViewer(item.id, attachment.id, attachment.name)"
                                           v-tooltip.bottom="tooltipForAttachementText(attachment)">
                                            {{ attachment.type_id == 1 ? attachment.name + ' (uchw.)' : attachment.name }}
                                        </a>

                                        <section class="points-list__actions">
                                            <button v-if="session.is_end == false && attachment.type_id === 7 && attachment.user_id === currentUserLid" class="points-list__action-btnatt" @click="handleEditAttClick(attachment, item)" v-tooltip.bottom="'Ustawienia'">
                                                <img src="../../../../assets/icons/components/edit.svg" class="points-list__action-icon" />
                                            </button>
                                            <button v-else-if="session.is_end == false && attachment.type_id !== 7" class="points-list__action-btnatt" @click="handleEditAttClick(attachment, item, attachment.type_id !== 7 && attachment.type_id !== 14 && attachment.type_id == 1)" v-tooltip.bottom="'Ustawienia'">
                                                <img src="../../../../assets/icons/components/edit.svg" class="points-list__action-icon" />
                                            </button>

                                            <button v-if="attachment.type_id == 1 && session.is_end == false" class="points-list__action-btnatt" @click="handlePassResolutionClick(attachment, item)" v-tooltip.bottom="'Podjęcie uchwały'">
                                                <img src="../../../../assets/icons/components/add-red.svg" class="points-list__action-icon" />
                                            </button>

                                            <button v-if="session.is_end == false && attachment.type_id === 7 && attachment.user_id === currentUserLid" class="points-list__action-btnatt points-list__action-btnatt--red" @click="handleDeleteAttClick(e, attachment, item)" v-tooltip.bottom="'Usuń'">
                                                <img src="../../../../assets/icons/components/delete-point.svg" class="points-list__action-icon" />
                                            </button>
                                            <button v-if="session.is_end == false && attachment.type_id !== 7" class="points-list__action-btnatt points-list__action-btnatt--red" @click="handleDeleteAttClick(e, attachment, item)" v-tooltip.bottom="'Usuń'">
                                                <img src="../../../../assets/icons/components/delete-point.svg" class="points-list__action-icon" />
                                            </button>

                                            <button class="points-list__action-btnatt" v-if="attachment.order > 1 && item.attachments.length > 1 && session.is_end == false" @click="moveAttachmentUp(item.attachments, attachment)">
                                                <img src="../../../../assets/icons/components/select-arrow.svg" class="points-list__action-icon" alt="Up" style="transform: rotate(180deg)" />
                                            </button>
                                            <button class="points-list__action-btnatt" v-if="attachment.order < item.attachments.length && item.attachments.length > 1 && session.is_end == false" @click="moveAttachmentDown(item.attachments, attachment)">
                                                <img src="../../../../assets/icons/components/select-arrow.svg" class="points-list__action-icon" alt="Down" />
                                            </button>
                                        </section>
                                    </li>
                                </attachmentDragable>
                            </section>

                            <!-- exported_votings_results -->
                            <section class="points-list__attachments" v-if="votingModule == 1 && zarzadzaniePrivilige == true && item.exported_votings_results != null && item.exported_votings_results.length > 0">
                                <section class="points-list__attachments__drag-votng">
                                    <div class="points-list__attachment-wrapper img-voting" v-for="( exvoting, index ) in item.exported_votings_results" :key="index">
                                        <img v-if="exvoting.hide" src="../../../../assets/icons/components/voting-lock.svg" style="height: 17px;" />
                                        <img v-else src="../../../../assets/icons/components/voting.svg" style="height: 17px;" />
                                        <a class="points-list__attachment-link__exported" @click="handleShowVotingResultsClick(exvoting.orginalPid, exvoting.id, exvoting.orginalSid)" v-tooltip.bottom="'Pokaż wyniki wyeksportowanego głosowania'">
                                            {{ exvoting.name }} {{exvoting.isPublic == false ? '(głosowanie tajne)' : ''}}
                                        </a>
                                        <section class="points-list__actions">
                                            <button v-if="session.is_end == false" class="points-list__action-btnatt points-list__action-btnatt--red" @click="handleDeleteExportingVotingClick(e, exvoting)" v-tooltip.bottom="'Usuń wyeksportowane głosowanie'">
                                                <img src="../../../../assets/icons/components/delete-point.svg" class="points-list__action-icon" />
                                            </button>

                                        </section>
                                    </div>
                                </section>
                            </section>

                            <section class="points-list__attachments" v-if="votingModule == 1 && zarzadzaniePrivilige == true && item.votings != null && item.votings.length > 0">
                                <votingDragable class="points-list__attachments__drag-votng"
                                                tag="ul"
                                                v-model="item.votings"
                                                item-key="order"
                                                :disabled="session.is_end == true || isAnyModalIsOpened == true"
                                                :group="{name: 'votingSameGroup', put: false, pull: false}"
                                                animation="500"
                                                easing="cubic-bezier(1, 0, 0, 1)"
                                                @end="endVotingOrder(item.votings)">
                                    <li :draggable="true" class="points-list__attachment-wrapper img-voting" v-for="( voting, index ) in item.votings" :key="index">
                                        <img v-if="voting.hide" src="../../../../assets/icons/components/voting-lock.svg" style="height: 17px;" />
                                        <img v-else src="../../../../assets/icons/components/voting.svg" style="height: 17px;" />
                                        <a v-if="voting.status != 3" class="points-list__attachment-link" :class="voting.hide ? 'voting_hide_class' : ''" @click="handleShowVotingInteractiveClick(item, voting)" v-tooltip.bottom="'Otwórz głosowanie'">
                                            {{ voting.name }} {{voting.isPublic == false ? '(głosowanie tajne)' : ''}} <PulseDot v-if="checkIsVotingError(voting.id)" color="red" v-tooltip.top="'Edytuj głosowanie, błędnie przypisane grupy'" />
                                        </a>
                                <a v-else class="points-list__attachment-link__green" @click="handleShowVotingResultsClick(item.id, voting.id, session.id)" v-tooltip.bottom="'Pokaż wyniki głosowania'">
                                            {{ voting.name }} {{voting.isPublic == false ? '(głosowanie tajne)' : ''}}
                                        </a>
                                <section class="points-list__actions">
                                <button v-if="voting.status != 3 && session.is_end == false" class="points-list__action-btnatt" @click="handleCreateVotingClick(item, voting.id)" v-tooltip.bottom="'Ustawienia głosowania'">
                                <img src="../../../../assets/icons/components/edit.svg" class="points-list__action-icon" />
                                            </button>
                                <button v-if="voting.status == 3 && session.is_end == false" class="points-list__action-btnatt" @click="handleEditVotingNameVotingClick(item, voting)" v-tooltip.bottom="'Ustawienia głosowania'">
                                <img src="../../../../assets/icons/components/edit.svg" class="points-list__action-icon" />
                                            </button>
                                <button v-if="session.is_end == false" class="points-list__action-btnatt" @click="handleDragVotingToPointClick(item, voting)" v-tooltip.bottom="'Przenieś głosowanie'">
                                <img src="../../../../assets/icons/components/arrows-from-dotted-line-solid.svg" class="points-list__action-icon" />
                                            </button>
                                <button v-if="voting.status == 3 && session.is_end == false" class="points-list__action-btnatt" @click="handleExportVotingToOtherSessionClick(item, voting)" v-tooltip.bottom="'Wyeksportuj wynik głosowania do innego posiedzenia...'">
                                <img src="../../../../assets/icons/components/share-from-square.svg" class="points-list__action-icon" />
                                            </button>
                                <button v-if="voting.status == 3 && session.is_end == false" class="points-list__action-btnatt" @click="handleCopyVotingClick(item, voting)" v-tooltip.bottom="'Kopiuj głosowanie (reasumpcja)'">
                                <img src="../../../../assets/icons/components/copy.svg" class="points-list__action-icon" alt="Copy" />
                                            </button>
                                <button v-if="session.is_end == false" class="points-list__action-btnatt points-list__action-btnatt--red" @click="handleDeleteVotingClick(e, voting.id, item.id)" v-tooltip.bottom="'Usuń głosowanie'">
                                <img src="../../../../assets/icons/components/delete-point.svg" class="points-list__action-icon" />
                                            </button>
                                <button class="points-list__action-btnatt" v-if="voting.order > 1 && item.votings.length > 1 && session.is_end == false" @click="moveVotingUp(item.votings, voting)">
                                <img src="../../../../assets/icons/components/select-arrow.svg" class="points-list__action-icon" alt="Up" style="transform: rotate(180deg)" />
                                            </button>
                                <button class="points-list__action-btnatt" v-if="voting.order < item.votings.length && item.votings.length > 1 && session.is_end == false" @click="moveVotingDown(item.votings, voting)">
                                <img src="../../../../assets/icons/components/select-arrow.svg" class="points-list__action-icon" alt="Down" />
                                            </button>
                                        </section>
                                    </li>
                                </votingDragable>
                            </section>

                            <section class="points-list__attachments">
                                <section class="points-list__attachments__drag-attachment">
                                    <div class="points-list__attachment-wrapper" v-for="( statement, index ) in item.statements" :key="index">
                                        <img src="../../../../assets/icons/components/note.svg" style="height: 17px;" />
                                        <a v-if="statement.autor == 0" class="points-list__attachment-link" @click="handleaddStatementClick(item, statement)" v-tooltip.bottom="'Ustawienia wypowiedzi'">
                                            Wypowiedź nr {{ statement.id }}
                                        </a>
                                        <a v-else class="points-list__attachment-link" @click="handleaddStatementClick(item, statement)" v-tooltip.bottom="'Ustawienia wypowiedzi'">
                                            {{ statement.autorName }} - wypowiedź
                                        </a>
                                        <section class="points-list__actions">
                                            <button v-if="session.is_end == false" class="points-list__action-btnatt" @click="handleaddStatementClick(item, statement)" v-tooltip.bottom="'Ustawienia wypowiedzi'">
                                                <img src="../../../../assets/icons/components/edit.svg" class="points-list__action-icon" />
                                            </button>
                                            <button v-if="session.is_end == false" class="points-list__action-btnatt points-list__action-btnatt--red" @click="handleDeleteStatementClick(e, item, statement)" v-tooltip.bottom="'Usuń wypowiedź'">
                                                <img src="../../../../assets/icons/components/delete-point.svg" class="points-list__action-icon" />
                                            </button>

                                        </section>
                                    </div>
                                </section>
                            </section>



                        </section>


                        <section v-if="item.is_done" class="points-list__point-done">
                            <section class="point-is-done">
                                <img src="../../../../assets/icons/panel/editSession/meeting-time.svg" class="point-is-done__img" />
                                <span>{{ getTimeEndPoint(item) }}</span>
                            </section>
                        </section>


                        <section class="points-list__actions points-list__actions_right-corner ">

                            <button class="points-list__action-btn-text"
                                    @click="() => { this.itemId = item.id; this.isResolution = true; handleFileUpload()}" v-if="exportPrivilige == true && zarzadzaniePrivilige == true && !isTemplate && session.is_end == false && checkCanRegistry()" v-tooltip.bottom="'Dodaj uchwałę'">
                                <img src="../../../../assets/icons/components/attachment-red.svg" class="points-list__action-icon" />
                            </button>

                            <button class="points-list__action-btn-text"
                                    @click="() => { this.itemId = item.id; handleFileUpload() }" v-if="exportPrivilige == true && zarzadzaniePrivilige == true && !isTemplate && session.is_end == false" v-tooltip.bottom="'Dodaj załącznik'">
                                <img src="../../../../assets/icons/components/attachment.svg" class="points-list__action-icon" />
                            </button>

                            <button class="points-list__action-btn-text"
                                    @click="handleCreateVotingClick(item, null)" v-if="votingModule == 1 && zarzadzaniePrivilige == true && !isTemplate && session.is_end == false" v-tooltip.bottom="'Dodaj głosowanie'">
                                <img src="../../../../assets/icons/components/voting-add.svg" class="points-list__action-icon" />
                            </button>

                            <button v-if="session.is_end == false" class="points-list__action-btn"
                                    @click="handleEditPointClick(item)" v-tooltip.bottom="'Ustawienia punktu'">
                                <img src="../../../../assets/icons/components/edit.svg"
                                     class="points-list__action-icon" />
                            </button>

                            <button v-if="session.is_end == false" class="
                                    points-list__action-btn
                                    points-list__action-btn--red
                                "
                                    @click="handleDeleteClick(e, item.id)" v-tooltip.bottom="'Usuń punkt'">
                                <img src="../../../../assets/icons/components/delete-point.svg"
                                     class="points-list__action-icon" />
                            </button>
                        </section>



                    </section>
    </section>
    <SesionPointsList :items="item.children"
                      :haveChildren="item.children.length > 0"
                      :level="level + 1"
                      :session="session"
                      :firstLevel="firstLevel"
                      :secondLevel="secondLevel"
                      :thirdLevel="thirdLevel"
                      :checkedPointIds="point_ids"
                      :isTemplate="isTemplate"
                      :wrongVotings="wrongVotings"
                      v-if="level < 2"
                      @changeActivePoint="(value) => { this.$emit('changeActivePoint', value) }"
                      @refreshList="this.$emit('refreshList')"
                      @reloadData="this.$emit('reloadData')"
                      @setPointIds="(value)=>{ this.point_ids = value; this.$emit('setPointIds', value); }"
                      @handleShowVotingInteractive="handleShowVotingInteractiveClick"
                      @handleShowVotingResults="handleShowVotingResultsClick"
                      @handleaddPointBlockVotingsResults="handleaddPointBlockVotingsResultsClick"
                      @handleEditVotingNameVoting="handleEditVotingNameVotingClick"
                      @handleDragVotingToPoint="handleDragVotingToPointClick"
                      @handleCopyVoting="handleCopyVotingClick"
                      @handleExportVotingToOtherSession="handleExportVotingToOtherSessionClick"
                      @handleCreateVoting="handleCreateVotingClick"
                      @handleAddNote="handleAddNoteClick"
                      @handleAddLink="handleAddLinkClick"
                      @handleUpdateFileName="handleUpdateFileNameClick"
                      @handleShowNote="handleShowNoteClick"
                      @handleaddStatement="handleaddStatementClick"
                      @handlePassResolution="handlePassResolutionClick"
                      @handleOpenAttachmentViewer="openAttachmentViewer"
                      @handleEditPoint="handleEditPointClick" />
        </li>
        </draggable>

        </section>
</template>

<script>
    import eSesjaInteractiveService from "../../../../services/esesja.interactive.service";
    import AttachmentService from "../../../../services/attachments.service";
    import SessionService from "../../../../services/session.service";
    import VotingService from "../../../../services/voting.service";
    import UploaderService from "../../../../services/uploader.service";

    import CheckBoxButton from "../../../BasicControls/CheckBoxButton.vue";
    import AgendaItemContextMenu from "../../../BasicControls/AgendaItemContextMenu.vue";
    import PointInfoPart from "../SessionPoints/Parts/PointInfoPart.vue";
    import PulseDot from "../../../BasicControls/PulseDot.vue";

    import { VueDraggableNext } from "vue-draggable-next";
    import { encryptStorage } from '../../../../services/encrypt.service';
    import AlertHelper from '@/helpers/alerts.helper.js';
    import PointsNumerationHelper from "../../../../helpers/pointsNumeration.helper";

    import moment from "moment";

    export default {
        name: "SesionPointsList",
        data() {
            return {
                exportPrivilige: encryptStorage.getItem('priv').eksport,
                zarzadzaniePrivilige: encryptStorage.getItem('priv').zarzadzanie,
                votingModule: encryptStorage.getItem('modules').g,
                point_ids: [],
                itemId: null,
                isResolution: false,
                tempContextMenu: null,
            };
        },
        components: {
            draggable: VueDraggableNext,
            votingDragable: VueDraggableNext,
            attachmentDragable: VueDraggableNext,
            CheckBoxButton,
            AgendaItemContextMenu,
            PointInfoPart,
            PulseDot
        },
        props: {
            items: Array,
            level: {
                type: Number,
                default: 0,
            },
            session: Object,
            firstLevel: Number,
            secondLevel: Number,
            thirdLevel: Number,
            checkedPointIds: Array,
            haveChildren: {
                type: Boolean,
                default: false,
            },
            isTemplate: {
                type: Boolean,
                default: false
            },
            wrongVotings: Array
        },
        mounted() {
            this.point_ids = this.checkedPointIds;

            document.addEventListener("mousedown", (e) => {
                const agendaContext = document.getElementById("js-context-agenda");
                if (agendaContext !== null) {
                    const isClickInside = agendaContext.contains(e.target);
                    if (!isClickInside) {
                        document
                            .querySelectorAll(".calendar-data")
                            .forEach((box) => (box.style.display = "none"));
                        agendaContext.style.display = "none";
                        agendaContext.removeAttribute("id");
                    }
                }
            });
        },
        updated() {
            this.point_ids = this.checkedPointIds;
        },
        computed: {
            getToken() {
                return this.$store.state.userStore.token;
            },
            
            isAnyModalIsOpened(){
                return this.$store.state.appStore.anySessionModalOpen;
            },

            currentUserLid() {
                return this.$store.state.userStore.userData.id;
            }
        },
        methods: {
            tooltipForAttachementText(item) {
                var str = 'Podgląd załącznika ';
                item.public ? str += '(publiczny, ' : str += '(niepubliczny, '
                item.visible_for_users ? str += 'widoczny dla użytkowników)' : str += 'niewidoczny dla użytkowników)'

                return str;
            },
            checkCanRegistry() {
                if (this.session != null && this.session.groups != null && this.session.groups.length > 0) {
                    if (this.session.groups.length > 1) {
                        return false;
                    }

                    if (this.session.groups[0].can_registry != null) {
                        return this.session.groups[0].can_registry;
                    }
                    else {
                        return false;
                    }
                }
                return false;
            },
            getPointUniqueId(item) {
                if (item.id === undefined || item.id === null) {
                    return `point-abc`
                }

                return `point-${item.id}`;
            },
            getTimeEndPoint(item) {
                if (item.is_done) {
                    return moment(item.done_at).format("DD-MM-YYYY, HH:mm");
                }
            },
            openContextMenu(e) {
                e.preventDefault();

                if (this.session.is_end == true)
                    return;

                var rect = e.target.getBoundingClientRect();
                var x = e.clientX - rect.left;
                var y = e.clientY - rect.top;

                const agendaContext = document.querySelectorAll(
                    ".agenda-item-context-menu"
                );
                agendaContext.forEach((block) => (block.style.display = "none"));
                const contextMenu = e.currentTarget.children[0];
                contextMenu.style.display = "block";
                contextMenu.id = "js-context-agenda";
                const element = document.getElementById(contextMenu.id);
                const viewportOffset = element.getBoundingClientRect();
                const top = viewportOffset.top;
                const left = viewportOffset.left;

                if (left < 0 || top > window.innerHeight) {
                    contextMenu.style.position = "absolute";
                    contextMenu.style.bottom = "auto";
                    contextMenu.style.left = "auto";
                    contextMenu.style.top = "0px";
                    contextMenu.style.right = "0px";
                } else {
                    contextMenu.style.position = "absolute";
                    contextMenu.style.bottom = "auto";
                    contextMenu.style.top = `${y}px`;
                    contextMenu.style.left = `${x}px`;
                    contextMenu.style.right = "auto";
                }
                this.tempContextMenu = contextMenu;
            },
            closeContextMenu() {
                if (this.tempContextMenu != null) {
                    this.tempContextMenu.style.display = "none";
                }
            },
            handleDeleteClick(e, id) {
                const actionToFire = () => {
                    this.$store.commit("showLoader");
                    SessionService.removePoint(id, this.session.id).then(() => {
                        this.$emit("refreshList");
                        this.$emit("refreshSessionVotings");

                        this.point_ids = this.point_ids.filter(
                            (point) => point !== id
                        );
                        this.$emit("setPointIds", this.point_ids);
                    });
                };
                this.$store.commit("showConfirmationAlert", actionToFire);
            },
            handleDeleteStatementClick(e, point, statement) {
                const actionToFire = () => {
                    this.$store.commit("showLoader");

                    if (point.id > 0 && statement.id > 0) {
                        AttachmentService.deleteStatementFromAgenda(point.id, statement.id).then(() => {
                            this.$emit("refreshList");
                        });
                    }
                };
                this.$store.commit("showConfirmationAlert", actionToFire);
            },
            handleDeleteAttClick(e, att, item) {
                const actionToFire = () => {
                    this.$store.commit("showLoader");

                    if (att.type_id == 7 || att.type_id == 6 || att.type_id == 14) {
                        AttachmentService.deleteNote(att.id, item.id).then(() => {
                            this.$emit("refreshList");
                        });
                    }
                    else {
                        AttachmentService.deleteFile(att.id, item.id).then(() => {
                            this.$emit("refreshList");
                        });
                    }
                };
                this.$store.commit("showConfirmationAlert", actionToFire);
            },
            handleDeleteVotingClick(e, id, pid) {
                if (this.votingModule == 1) {
                    const actionToFire = () => {
                        this.$store.commit("showLoader");
                        VotingService.removeVoting(id, pid).then(() => {
                            this.$emit("refreshList");
                            this.$emit("refreshSessionVotings");
                        });
                    };
                    this.$store.commit("showConfirmationAlert", actionToFire);
                }                
            },
            handleDeleteExportingVotingClick(e, exvoting) {
                if (this.votingModule == 1) {
                    const actionToFire = () => {
                        this.$store.commit("showLoader");

                        var data = {
                            id: exvoting.elemId,
                            pid: exvoting.pid,
                            sid: exvoting.sid,
                            gid: exvoting.id
                        };

                        VotingService.removeExportingVoting(exvoting.sid, data).then(() => {
                            this.$emit("refreshList");
                        });
                    };
                    this.$store.commit("showConfirmationAlert", actionToFire);
                }
            },
            handlePassResolutionClick(item, point) {
                this.$emit("handlePassResolution", item, point);
            },
            handleEditAttClick(item, point, isResolution = false) {
                if (item.type_id == 6) {
                    this.handleAddLinkClick(item, point, true);
                }
                else if (item.type_id == 7 || item.type_id == 14) {
                    this.handleAddNoteClick(item, point, true);
                }
                else {
                    this.handleUpdateFileNameClick(item, point, true, isResolution);
                }
            },
            handleAddNoteClick(item, point, edit) {
                this.$emit("handleAddNote", item, point, edit);
            },
            handleaddIsDoneClick(e, point) {
                SessionService.setPointIsDone(point.id, this.session.id).then(() => {
                    this.$emit("refreshList");
                });
            },
            handleaddIsHideClick(e, point) {
                SessionService.setPointIsHide(point.id, this.session.id).then(() => {
                    this.$emit("refreshList");
                });
            },
            handleaddIsTakenOffClick(e, point) {
                SessionService.setPointIsTakenOff(point.id, this.session.id).then(() => {
                    this.$emit("refreshList");
                });
            },
            async handleSetActivePointClick(e, point) {
                if (point.id != null && point.id >= 0) { 
                    if (point.id == this.$store.state.interactiveStore.activePoint) {
                        this.$store.commit("setActivePoint", 0);
                        await eSesjaInteractiveService.changeActivePointInSession(0, this.session.id, false);
                    }
                    else {
                        this.$store.commit("setActivePoint", point.id);
                        await eSesjaInteractiveService.changeActivePointInSession(point.id, this.session.id, true);
                    }

                    this.$emit("changeActivePoint", point);
                }
            },
            handleaddPointBlockVotingsResultsClick(point) {
                this.$emit("handleaddPointBlockVotingsResults", point);
            },
            isSomeVotingsResultsIsPoint(item) {
                var result = false;
                if (item != null && item.votings != undefined && item.votings != null && item.votings.length > 0) {
                    var r = item.votings.filter(x => x.status == 3);
                    if (r !== undefined && r !== null && r.length > 0) {
                        result = true;
                    }
                }
                return result;
            },
            async handleaddStatementClick(point, stat) {
                this.$emit("handleaddStatement", point, stat);
            },
            handleAddLinkClick(item, point, edit) {
                this.$emit("handleAddLink", item, point, edit);
            },
            handleUpdateFileNameClick(item, point, edit, isResolution) {
                this.$emit("handleUpdateFileName", item, point, edit, isResolution);
            },
            handleShowNoteClick(item, id) {
                this.$emit("handleShowNote", item, id);
            },
            handleGoToLinkClick(item) {
                window.open(item, '_blank');
            },
            handleEditPointClick(item) {
                this.$emit("handleEditPoint", item);
            },
            async handleShowVotingResultsClick(point, voting, sessionId) {
                this.$emit("handleShowVotingResults", point, voting, sessionId);
            },
            async handleShowVotingInteractiveClick(point, voting) {
                this.$emit("handleShowVotingInteractive", point, voting, false);
            },
            async handleCreateVotingClick(item, edit) {
                this.$emit("handleCreateVoting", item, edit);
            },
            handleEditVotingNameVotingClick(item, voting) {
                this.$emit("handleEditVotingNameVoting", item, voting);
            },
            handleDragVotingToPointClick(item, voting) {
                this.$emit("handleDragVotingToPoint", item, voting);
            },
            handleExportVotingToOtherSessionClick(item, voting) {
                this.$emit("handleExportVotingToOtherSession", item, voting);
            },
            handleCopyVotingClick(item, voting){
                this.$emit("handleCopyVoting", item, voting)
            },
            canBePlaced(to, from, element) {
                const isMaxStack = to.options.class.split(" ").indexOf("max-stack");
                const hasChildren = element.classList.contains("has-children");
                const hasMaxChildrens = element.classList.contains("has-max-children");

                if ((isMaxStack !== -1 && hasChildren) || hasMaxChildrens) {
                    return false;
                }
                return true;
            },
            handleAddAttachementFromRepositoryClick(element, reso) {

                if (reso == true && this.checkCanRegistry() == false)
                    return;

                this.isResolution = reso;
                this.$router.push({ name: 'doc', query: { ses: true, sesId: this.session.id, grId: this.$route.params.groupid, pId: element.id, resolution: this.isResolution } });
            },
            childrenCheck(items) {
                let itemLevels = 1;
                items.forEach((item) => {
                    if (item.children.length > 0) {
                        itemLevels += 1;
                        this.childrenCheck(item.children);
                    }
                });
                return itemLevels;
            },
            checkIsVotingError(votingId) {
                if (this.wrongVotings === undefined || this.wrongVotings === null || this.wrongVotings.length === 0){
                    return false;
                }
                return this.wrongVotings.find(x => x.id == votingId) != null ? true : false;
            },
            handleFileUpload() {
                try {
                    this.closeContextMenu();
                    let input = document.createElement('input');
                    input.type = 'file';
                    input.accept = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.png,.jpg,.jpeg,.xls,.txt,.xlsx,.zipx,.xml,.zip,.csv';
                    input.multiple = true;
                    input.click();
                    input.addEventListener('change', async (event) => {
                        try {
                            this.$store.commit("showLoader");
                            await UploaderService.UploadPointAttachements(this.itemId, event, this.isResolution);
                        }
                        catch (e) {
                            this.$store.commit("hideLoader");
                            this.$swal(AlertHelper.errorAlert(e));
                        }
                        finally {
                            this.isResolution = false;
                            this.$emit("refreshList");
                            // hide loader
                        }
                    });
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            
            async openAttachmentViewer(pointId, attachmentId, name) {
                await this.$emit("handleOpenAttachmentViewer", pointId, attachmentId, name);
            },
            
            
            async getFile(pid, id, name) {
                try {
                    var result = (await AttachmentService.downloadAttachment(pid, id));
                    if (result.status == 200) {
                        const contentType = result.headers['content-type'];
                        var a = document.createElement('a');
                        var blob = new Blob([result.data], { 'type': contentType });
                        a.href = window.URL.createObjectURL(blob);
                        a.download = name;
                        a.click();
                    }

                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            filterIndex(index, number) {
                return PointsNumerationHelper.filterIndex(index, number, this.level, this.firstLevel, this.secondLevel, this.thirdLevel);
            },
            isInteractive() {
                return this.$store.state.appStore.isInInteractiveMode;
            },

            prepareListToDrag(list) {
                return list.forEach((item, index) => item.order = index + 1);
            },
            async endAttachemntOrder(data) {
                try {
                    this.$store.commit("showLoader");
                    data.forEach((item, index) => item.order = index + 1);

                    let agenda = new Set(data.map(item => item.agenda_item_id));
                    if (agenda.size != 1) {
                        throw new Error();
                    }

                    let result = await SessionService.reorderPointAttachemnt([...agenda][0], data);
                    if (result.status == 200) {
                        this.$emit('refreshList')
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            async endVotingOrder(data) {
                try {
                    this.$store.commit("showLoader");

                    if (this.votingModule == 1) {
                        
                        data.forEach((item, index) => item.order = index + 1);

                        let agenda = new Set(data.map(item => item.pid));
                        if (agenda.size != 1) {
                            throw new Error();
                        }

                        let result = await VotingService.reorderVotings([...agenda][0], data);

                        if (result.status === 200) {
                            this.$emit('refreshList')
                        }
                    } 
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            async moveVotingUp(list, voting) {
                try {
                    this.$store.commit("showLoader");

                    if (this.votingModule == 1) {

                        if (voting.order <= 1) {
                            return;
                        }

                        var indexOfVoting = list.indexOf(voting);
                        list[indexOfVoting].order -= 1;
                        list[indexOfVoting - 1].order += 1;

                        let bck = list[indexOfVoting];
                        let bck2 = list[indexOfVoting - 1];
                        list[indexOfVoting - 1] = bck;
                        list[indexOfVoting] = bck2;

                        let result = await VotingService.reorderVotings(voting.pid, list);

                        if (result.status === 200) {
                            this.$emit('refreshList')
                        }

                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            async moveVotingDown(list, voting){
                try {
                    this.$store.commit("showLoader");

                    if (this.votingModule == 1) {
                        if (voting.order >= list.length) {
                            return;
                        }

                        var indexOfVoting = list.indexOf(voting);
                        list[indexOfVoting].order += 1;
                        list[indexOfVoting + 1].order -= 1;

                        let bck = list[indexOfVoting];
                        let bck2 = list[indexOfVoting + 1];
                        list[indexOfVoting + 1] = bck;
                        list[indexOfVoting] = bck2;

                        let result = await VotingService.reorderVotings(voting.pid, list);

                        if (result.status === 200) {
                            this.$emit('refreshList')
                        }
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            async moveAttachmentUp(list, item) {
                try {
                    this.$store.commit("showLoader");
                    if (item.order <= 1) {
                        return;
                    }

                    var indexOfAttach = list.indexOf(item);
                    list[indexOfAttach].order -= 1;
                    list[indexOfAttach - 1].order += 1;

                    let bck = list[indexOfAttach];
                    let bck2 = list[indexOfAttach - 1];
                    list[indexOfAttach - 1] = bck;
                    list[indexOfAttach] = bck2;

                    let result = await await SessionService.reorderPointAttachemnt(item.agenda_item_id, list);

                    if (result.status === 200) {
                        this.$emit('refreshList')
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            async moveAttachmentDown(list, item) {
                try {
                    this.$store.commit("showLoader");
                    if (item.order >= list.length) {
                        return;
                    }

                    var indexOfAttach = list.indexOf(item);
                    list[indexOfAttach].order += 1;
                    list[indexOfAttach + 1].order -= 1;

                    let bck = list[indexOfAttach];
                    let bck2 = list[indexOfAttach + 1];
                    list[indexOfAttach + 1] = bck;
                    list[indexOfAttach] = bck2;

                    let result = await SessionService.reorderPointAttachemnt(item.agenda_item_id, list);

                    if (result.status === 200) {
                        this.$emit('refreshList')
                    }
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },

            getUserName(personId) {
                try {
                    let groups = [...this.$store.state.appStore.groups];
                    let people = groups
                        .map(item => item.people)
                        .reduce((a, b) => { return a.concat(b) }, [])
                        .filter(item => item.id === personId);

                    if (people.length !== 1) {
                        return "";
                    }
                    let person = people[0];
                    return `${person.first_name} ${person.last_name}`;
                }
                catch {
                    return "";
                }
            }
        },
    };
</script>

<style lang="scss" scoped>
    .point-is-done {
        font-size: 14px;
        height: 41px;
        float: right;
        min-width: 200px;
        text-align: right;

        &__img {
            margin-right: 5px;
            vertical-align: middle;
        }
    }

    .voting_hide_class {
        color: grey !important;
    }

    .points-list {
        width: 100%;
        position: relative;

        &__action-icon{
            max-height: 15px;
            max-width: 20px;
        } 
        
        &__point-box-left {
            display: flex;
            flex-direction: column;
            min-height: 41px;
        }

        &__arrow {
            position: absolute;
            top: -50px;
            left: -37px;
            display: block;

            &--next {
                top: -120px;
            }
        }

        &__arrow-next {
            position: absolute;
            top: 60px;
        }

        &__attachments {
            margin-left: 50px;
            align-items: center;
            flex-wrap: wrap;



            &__drag-votng {
                padding: 0;
            }

            &__drag-attachment {
                padding: 0;
            }
        }

        &__referer {
            font-weight: normal;
            font-size: 14px;
            color: #333956;
            padding-top: 10px;
        }

        &__attachment-link {
            font-weight: normal;
            font-size: 14px;
            color: #333956;
            cursor: pointer;
            margin-left: 11px;

            &:hover {
                text-decoration-line: underline;
                color: #808396;
            }

            &__green {
                color: #4cad39;
                font-size: 14px;
                font-weight: bold;
                font-size: 14px;
                cursor: pointer;
                margin-left: 11px;
            }

            &__exported {
                color: #1d51b9;
                font-size: 14px;
                font-weight: bold;
                font-size: 14px;
                cursor: pointer;
                margin-left: 11px;
            }

            &__public {
                font-size: 14px;
                font-weight: bold;
                font-size: 14px;
                cursor: pointer;
                margin-left: 11px;
            }
        }

        &__attachment-wrapper {
            margin-right: 42px;
            display: flex;
            align-items: center;
            position: relative;
            margin-top: 10px;

            &:nth-child(1)::after {
                width: 0px;
                height: 0px;
            }

            &:after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -21px;
                width: 1px;
                height: 16px;
                background: #dde0e7;
            }
        }

        &__actions {
            opacity: 0;
            transition: all 0.2s ease-in-out;
            display: flex;
            align-items: flex-start;
        }

        &__actions_right-corner {
            display: none;
        }

        &__point-done {
            display: flex;
            align-items: flex-start;
            height: 41px;
        }

        &__data-wrapper {
            display: flex;
            position: relative;
            width: 100%;
        }

        &__checkbox {
            position: relative;
            display: flex;
        }

        &__checkbox-label {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 6;
        }

        &__point-box {
            padding: 18px;
            padding-bottom: 18px;
            border: 1px solid #dce4ee;
            box-sizing: border-box;
            border-radius: 12px;
            display: flex;
            justify-content: space-between;
            transition: all 0.2s ease-in-out;
            width: 100%;
            cursor: move;
            position: relative;

            &--selected {
                border: 1px solid #00082c;

                &:hover {
                    border-color: #00082c !important;
                }
            }

            &--hide {
                border: 1px solid red !important;

                &:hover {
                    border-color: red;
                }
            }

            &--active {
                border: 1px solid green !important;
                background: #f7fff7;

                &:hover {
                    border-color: green;
                }
            }

            &:hover .points-list__actions {
                opacity: 1;
            }

            &:hover .points-list__actions_right-corner {
                display: flex;
                height: 41px;
            }

            &:hover .points-list__point-done {
                display: none;
            }

            &:hover {
                border-color: #bbc2ce;
            }

            &--no-border {
                padding: 10px 10px 0px 10px;
                border: 0px;
                margin: 0px !important;
            }
        }

        &__number {
            margin-left: 48px;
        }

        &__name {
            font-weight: normal;
            font-size: 16px;
            line-height: 132%;
            overflow-wrap: anywhere;

            &--bold {
                font-weight: 600;
                font-size: 18px;
                line-height: 132%;
                color: #00082c;
            }

            &--pointtakenoff {
                text-decoration: line-through !important;
            }
        }

        &__number {
            font-weight: 600;
            margin-right: 8px;
        }

        &__file-input {
            display: none;
        }

        &__action-btn {
            width: 32px;
            height: 32px;
            margin-left: 8px;
            background-color: white;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                border: 1px solid hsla(354, 96%, 43%, 0.317);
            }
        }


        &__action-btn-text {
            width: 32px;
            height: 32px;
            margin-left: 8px;
            background-color: white;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;


            img {
                width: 2.2em
            }

            &:hover {
                background-color: #e8ebef;
            }

            &--red {
                border: 1px solid hsla(354, 96%, 43%, 0.317);
            }
        }

        &__action-btnatt {
            width: 25px;
            height: 25px;
            margin-left: 8px;
            background-color: white;
            border: none;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #e8ebef;
            }
        }
    }

    .session-point {
        padding: 7px;
        padding-bottom: 0px;
        background: #ffffff;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        border-radius: 12px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        transition: all 0.2s ease-in-out;

        &--selected {
            border: 1px solid #00082c !important;

            &:hover {
                border-color: #00082c;
            }
        }

        &--hide {
            border: 1px solid red !important;

            &:hover {
                border-color: red;
            }
        }

        &--active {
            border: 1px solid green !important;
            background: #f7fff7;

            &:hover {
                border-color: green;
            }
        }

        &:hover {
            border-color: #8995a9;
        }
    }

    .dragged-item {
        .points-list--no-children {
            display: none;
        }
    }

    .dragArea {
        padding-bottom: 18px;
        list-style: none;
        padding-left: 0px;
    }

    .no-drag {
        padding-bottom: 18px;
    }

    .children {
        margin-left: 28px;
        border: 0px;
        margin-bottom: 0px;
        padding: 0px;
        margin-top: 0px;

        &:nth-child(1) {
            margin-top: 18px;
        }

        &--last {
            margin-top: 18px;

            .points-list__point-box {
                padding-bottom: 18px;
            }
        }
    }

    .ghost,
    .ghost--first-level {
        background-color: #eef1f7;
        margin-bottom: 18px;

        .points-list--no-children {
            display: none;
        }

        .points-list__arrow {
            display: none;
        }

        .points-list {
            .session-point {
                background-color: #eef1f7;
            }
        }
    }
</style>
