import store from "../store/mainStore";
import ApiService from "./api.service";
import { expireTimeMinus } from "../config/config";
import router from "../router/main-router";
import { encryptStorage } from '../../src/services/encrypt.service';

class UserService extends ApiService {
    constructor() {
        super();
        this.refreshTokenInterval = null;
        this.publicUrls = ["/PublicSession", "/PublicSessionInfo", "/user/email-confirm", "/user/forgot-password", "/user/reset-password", "/adfs",  "/loggedout"];
    }

    test() {
        return this.post(`Auth/test`);
    }

    login(login, password) {
        store.commit("showLoader");
        return this.post(`Auth/login`, {
            login,
            password,
        }).then(async (response) => {
            if (response.status == 200) {
                await this._internalLogin(response.data);
            }
            else {
                this.destroyer();
            }
        }).catch(() => {
            this.destroyer();
        })
    }

    loginAdfs(login) {
        return this.post("Auth/login-adfs-esesja", { login }).then((response) => {
            if (response.status == 200) {

                var user = {
                    token_type: response.data.token_type,
                    expires_in: response.data.expires_in,
                    access_token: response.data.access_token,
                    user: response.data.user,
                    accountErrors: response.data.accountErrors
                };

                var userState = this._getDefaultUserState();
                userState.autolockEnable = response.data.accountSettings.autolockEnable;
                userState.autolockInterval = response.data.accountSettings.autolockInterval;
                encryptStorage.setItem('priv', response.data.priv);
                encryptStorage.setItem('modules', response.data.modules);
                encryptStorage.setItem('userState', userState)
                this.updateStoreBasedOnStorage();
                store.commit("setUser", user);
                store.commit("setClientSettings", response.data.clientSettings);
                store.commit("setUserAccountSettings", response.data.userAccountSettings);
                store.commit("setLogoBase64", response.data.clientLogo);

                if (this.refreshTokenInterval != null)
                    clearInterval(this.refreshTokenInterval);

                this.reNewTimeOut(response.data.expires_in);
                router.push({ name: "home" });
            }
            else {
                this.destroyer();
            }
        }).catch(() => {
            this.destroyer();
        })
    }

    async _internalLogin(data) {
        var user = {
            token_type: data.token_type,
            expires_in: data.expires_in,
            access_token: data.access_token,
            user: data.user,
            accountErrors: data.accountErrors
        };

        var userState = this._getDefaultUserState();
        userState.autolockEnable = data.accountSettings.autolockEnable;
        userState.autolockInterval = data.accountSettings.autolockInterval;
        encryptStorage.setItem('priv', data.priv);
        encryptStorage.setItem('modules', data.modules);
        encryptStorage.setItem('userState', userState)
        this.updateStoreBasedOnStorage();
        store.commit("setUser", user);
        store.commit("setClientSettings", data.clientSettings);
        store.commit("setUserAccountSettings", data.userAccountSettings);
        store.commit("setLogoBase64", data.clientLogo);

        if (this.refreshTokenInterval != null)
            clearInterval(this.refreshTokenInterval);

        this.reNewTimeOut(data.expires_in);
        await router.push({ name: "home" });
    }

    mwcLogin(token) {
        return this.post("Auth/mwc/login", { token: token }).then(async response => {
            if (response.status === 200) {
                await this._internalLogin(response.data);
            }
            else {
                this.destroyer();
            }
        }).catch(() => {
            this.destroyer();
        })
    }

    logout() {
        store.state.userStore.isLoading = true;
        return this.post("Auth/logout")
            .then((response) => {
                this.destroyer(response.data);
            })
            .catch((e) => {
                console.log("wpad�em do catch?")
                console.log(e)
                this.destroyer();
            });
    }

    refreshToken() {
        return this.post("Auth/refresh").then((response) => {
            if (response.status == 200) {
                store.commit("reNew", response.data);
            }
            else {
                this.destroyer();
            }
        }).catch(() => {
            this.destroyer();
        });
    }

    updateLockState(lockState) {
        let internalState = encryptStorage.getItem('userState');
        if (internalState === undefined || internalState === null) {
            internalState = this._getDefaultUserState();
        }
        internalState.isAppLock = lockState
        encryptStorage.setItem('userState', internalState)
        this.updateStoreBasedOnStorage();
    }

    updateAutolockSettings(autolock) {
        let internalState = encryptStorage.getItem('userState');
        if (internalState === undefined || internalState === null) {
            internalState = this._getDefaultUserState();
        }
        internalState.autolockEnable = autolock.autolockEnable;
        internalState.autolockInterval = autolock.autolockInterval;
        encryptStorage.setItem('userState', internalState)
        this.updateStoreBasedOnStorage();
    }

    _getDefaultUserState() {
        return {
            isAppLock: false,
            autolockEnable: false,
            autolockInterval: 10000,
        }
    }

    updateStoreBasedOnStorage() {
        try {
            let internalState = encryptStorage.getItem('userState');

            if (internalState === undefined || internalState === null) {
                this.logout();
                return;
            }

            if (internalState.isAppLock === undefined || internalState.isAppLock === null)
                return;

            store.commit("updateUserLockApplication", internalState.isAppLock);
            if (internalState.autolockEnable === undefined || internalState.autolockEnable === null)
                return;

            store.commit("updateUserLockConfiguration", internalState);
        }
        catch {
            return;
        }
    }


    reNewTimeOut(expire_time) {
        const expire = (expire_time - expireTimeMinus) * 1000;
        this.refreshTokenInterval = setInterval(() => {
            this.refreshToken();
        }, expire);
    }

    me() {
        store.commit("showLoader");
        return this.post("Auth/me")
            .then((response) => {
                if (response.status == 200) {
                    store.commit("hideLoader");
                    response.data.email = store.state.userStore.userData.email;

                    var user = {
                        token_type: response.data.token_type,
                        expires_in: response.data.expires_in,
                        access_token: response.data.access_token,
                        user: response.data.user,
                        accountErrors: response.data.accountErrors
                    };
                    store.commit("setUser", user);

                    store.commit("setClientSettings", response.data.clientSettings);
                    store.commit("setUserAccountSettings", response.data.userAccountSettings);
                    store.commit("setLogoBase64", response.data.clientLogo);

                    if (this.refreshTokenInterval != null)
                        clearInterval(this.refreshTokenInterval);

                    this.reNewTimeOut(response.data.expires_in);
                }
                else {
                    this.destroyer();
                }
            })
            .catch(() => {
                this.destroyer();
            });
    }
    getCurrentRid() {
        return this.get("Auth/current/rid");
    }

    getPersonalInfo() {
        return this.get("user/personal-data");
    }

    async updatePersonalInfo(data) {
        let response = await this.post("user/personal-data", data);
        if (response.status === 200)
            await this.getAccountErrors();

        return response;
    }

    updateOwnPassword(data) {
        return this.post("user/change-own-password", data);
    }

    destroyer(link = 'dest') {
        const currentUrl = window.location.href;
        const pb = this.publicUrls.some(e => { return currentUrl.includes(e) && e !== "/" });

        if (!pb) {
            store.commit("destroyUser");
            encryptStorage.removeItem('priv');
            encryptStorage.removeItem('modules');
            encryptStorage.removeItem('userState');
            store.commit("turnOffInteractive");

            if (this.refreshTokenInterval != null)
                clearInterval(this.refreshTokenInterval);

            if (link == 'dest') {
                router.push({ name: "Login" });
            }
            else {
                router.push({ name: "LoggedoutComponent", params: { link: link } });
            }   
        }
    }

    updateOwnPin(data) {
        return this.post("user/change-own-pin", data);
    }

    unlokcApplication(data) {
        return this.post("auth/unlock/pin", data);

    }

    getAccountSettings() {
        return this.get("user/account-settings");
    }

    updateAccountSettings(data) {
        return this.post("user/account-settings", data);
    }

    updateEmailSettings(data) {
        return this.post("user/account-email-settings", data);
    }

    confirmEmailAddress(token) {
        let request = {
            token: token
        }
        return this.put(`user/email-confirmation`, request);
    }

    sendEmailActivation() {
        return this.post(`user/send-email-activation`);
    }

    forgotPasswordRequest(data) {
        return this.post(`user/user-forgot-password`, data);
    }
    validateResetPasswordToken(token) {
        let request = {
            token: token
        }
        return this.put(`user/user-validate-reset-password`, request);
    }

    resetUserPassword(data) {
        return this.put(`user/user-reset-password`, data);
    }

    async getAccountErrors() {
        let response = await this.get(`user/user-get-account-errors`);
        if (response.status !== 200)
            throw await Promise.reject(response);

        store.commit('updateAccountErrors', response.data);

        return response;
    }

    loginADFS(elem) {
        return this.get(`auth/adfs/${elem}`, {
            headers: {
                "Cache-Control": "no-cache",
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
            }
        });
    }

    getADFSOrganizations() {
        return this.get(`auth/getADFSOrganizations`);
    }

    noShowAgainPasswordReminder() {
        return this.post(`user/noShowAgainPasswordReminder`)
    }

    remindAboutChangePasswordLater() {
        return this.post(`user/remindAboutChangePasswordLater`)
    }

    getKidRid() {
        return this.get(`user/get-kid-rid`);
    }

    getAdminUserInvitationData() {
        return this.get(`user/getAdminUserInvitationData`)
    }
}

export default new UserService();
